import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import DataTable from "react-data-table-component";

import bannervideo from "../../assets/bitnevex-images/Bitnevex.mp4";
import bannerimg from "../../assets/Nexchange-images/nex-banner.png";
import icon1 from "../../assets/Nexchange-images/nex-sec2-icon1.png";
import icon2 from "../../assets/Nexchange-images/nex-sec2-icon2.png";
import icon3 from "../../assets/Nexchange-images/nex-sec2-icon3.png";
import icon4 from "../../assets/Nexchange-images/nex-sec2-icon4.png";
import card2 from "../../assets/Nexchange-images/Rectangle.png";
import sec3card from "../../assets/Nexchange-images/nex-sec3.png";
import icon5 from "../../assets/Nexchange-images/nex-sec3-icon.png";
import cardicon from "../../assets/Nexchange-images/nex-sec3-cardicon.png";
import chart from "../../assets/Nexchange-images/chart.png";
import sun from "../../assets/Nexchange-images/nex-sec5.png";
import icon6 from "../../assets/Nexchange-images/nexsec5-iocn1.png";
import icon7 from "../../assets/Nexchange-images/nexsec5-icon2.png";
import icon8 from "../../assets/Nexchange-images/nexsec5-icon3.png";
import icon9 from "../../assets/Nexchange-images/nexsec5-icon4.png";
import icon10 from "../../assets/Nexchange-images/nexsec5-icon5.png";
import scan from "../../assets/Nexchange-images/scan.png";
import appstore from "../../assets/Nexchange-images/appstore.png";
import playstore from "../../assets/Nexchange-images/playstore.png";
import phone2 from "../../assets/bitnevex-images/mobile2.png";
import Sun from "./Homepages/Sun";

import { MdKeyboardArrowRight } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";

import Moon from "./Homepages/Moon";
import Mobileone from "../../assets/bitnevex-images/mobileone.mp4";
import Landingmobile from "../../assets/bitnevex-images/mobile-bitnevex.mp4";
import { Accordion } from "react-bootstrap";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";


export default function Home() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      width: "15%",
    },
    {
      name: "",
      selector: (row) => row.coin,
      width: "23%",
    },
    {
      name: "Last Price",
      selector: (row) => row.price,
      width: "15%",
      center: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {row.price}
        </div>
      ),
    },
    {
      name: "24H Change",
      selector: (row) => row.change,
      width: "12%",
      center: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {row.change}
        </div>
      ),
    },
    {
      name: "Chart",
      selector: (row) => row.chart,
      width: "20%",
      center: true,
    },
    {
      name: "",
      selector: (row) => row.trade,
      width: "15%",
    },
  ];

  const data = [
    {
      id: 1,
      name: <span className="nex-card-tx1">Bitcoin</span>,
      coin: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(185, 130, 255, 1)" }}
        >
          BTC
        </span>
      ),
      price: <span className="nex-card-tx1">$56,290.30</span>,
      change: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(13, 187, 124, 1)" }}
        >
          +1.68%
        </span>
      ),
      chart: <img src={chart} className="chart-img" alt="nexchange-home" />,
      trade: (
        <a className="nex-card-tx2 opacity-100 pe-3" href="#">
          Trade Now
          <BsArrowRightShort className="nex-card-tx3" />
        </a>
      ),
    },
    {
      id: 2,
      name: <span className="nex-card-tx1">Ethereum</span>,
      coin: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(185, 130, 255, 1)" }}
        >
          ETH
        </span>
      ),
      price: <span className="nex-card-tx1">$4,284.81</span>,
      change: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(13, 187, 124, 1)" }}
        >
          +4.36%
        </span>
      ),
      chart: <img src={chart} className="chart-img" alt="nexchange-home" />,
      trade: (
        <a className="nex-card-tx2 opacity-100 pe-3" href="#">
          Trade Now
          <BsArrowRightShort className="nex-card-tx3" />
        </a>
      ),
    },
    {
      name: <span className="nex-card-tx1">Cardano</span>,
      coin: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(185, 130, 255, 1)" }}
        >
          ADA
        </span>
      ),
      price: <span className="nex-card-tx1">$1.88</span>,
      change: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(13, 187, 124, 1)" }}
        >
          +3.43%
        </span>
      ),
      chart: <img src={chart} className="chart-img" alt="nexchange-home" />,
      trade: (
        <a className="nex-card-tx2 opacity-100 pe-3" href="#">
          Trade Now
          <BsArrowRightShort className="nex-card-tx3" />
        </a>
      ),
    },
    {
      name: <span className="nex-card-tx1">Wax</span>,
      coin: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(185, 130, 255, 1)" }}
        >
          BTC
        </span>
      ),
      price: <span className="nex-card-tx1">$0.97</span>,
      change: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(255, 130, 130, 1)" }}
        >
          -2.62%
        </span>
      ),
      chart: <img src={chart} className="chart-img" alt="nexchange-home" />,
      trade: (
        <a className="nex-card-tx2 opacity-100 pe-3" href="#">
          Trade Now
          <BsArrowRightShort className="nex-card-tx3" />
        </a>
      ),
    },
    {
      name: <span className="nex-card-tx1">Polkadot</span>,
      coin: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(185, 130, 255, 1)" }}
        >
          DOT
        </span>
      ),
      price: <span className="nex-card-tx1">$42.22</span>,
      change: (
        <span
          className="nex-card-tx1"
          style={{ color: "rgba(13, 187, 124, 1)" }}
        >
          +7.56%
        </span>
      ),
      chart: <img src={chart} className="chart-img" alt="nexchange-home" />,
      trade: (
        <a className="nex-card-tx2 opacity-100 pe-3" href="#">
          Trade Now
          <BsArrowRightShort className="nex-card-tx3" />
        </a>
      ),
    },
  ];

  const cardsData = [
    { src: icon6, text: 'Trusted Digital Asset' },
    { src: icon7, text: 'Trade with Confidence' },
    { src: icon8, text: 'Trends & Market data' },
    { src: icon9, text: 'Real-time Notification' },
    { src: icon10, text: 'Lock screen' },
  ];

  return (
    <div className="nexchange-homepage">



      <section className="nexchange-banner-section position-relative">
        <video
          id="video-background"
          width="100%"
          height="100%"
          autoPlay={true}
          muted
          poster
          className="desk-vid-d-none"
        >
          <source
            src={bannervideo}
            type="video/mp4 "
          />
        </video>
        <video
          id="video-background"
          width="100%"
          height="100%"
          autoPlay={true}
          muted
          poster
          className="mobile-vid-d-none"
        >
          <source
            src={Landingmobile}
            type="video/mp4 "
          />
        </video>
        <div
          className="nex-banner-card text-center"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-delay="3000"
        >
          <p className="nex-banner-tx">
            The Hub of Secure Crypto Trading
          </p>
          <button className="nex-connect-btn mt-2">Connect Now</button>
        </div>
      </section>


      <section className="nexchange-second-section my-5">
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="sec2-card-blur"></div>
            <div className="col-lg-9 col-md-11  ">
              <div className="card border-0" data-aos="fade-up">
                <div className="card-body py-0">
                  <div className="row g-4">
                    <div className="col-xl-8 col-lg-7 col-md-8">
                      <h5 className="nex-cardtop-tx my-lg-5 my-3" >
                        Explore a Range of Tailored Services
                      </h5>
                      <div className="">
                        <div className="d-flex gap-lg-5 gap-2 nexchange-second-mmm">
                          <img
                            src={icon1}
                            className="nexsec2-icon-img"
                            alt="nexchange-home"
                          />
                          <div>
                            <h5 className="nex-card-tx1">Spot Trading</h5>
                            <p className="nex-card-tx2 mb-0">
                              Easily buy, sell, or trade crypto at live market rates.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex gap-lg-5 gap-2 nexchange-second-mmm">
                          <img
                            src={icon2}
                            className="nexsec2-icon-img"
                            alt="nexchange-home"
                          />
                          <div>
                            <h5 className="nex-card-tx1">Perpetual Futures</h5>
                            <p className="nex-card-tx2 mb-0">
                              Enter contracts to profit by going long or short.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex gap-lg-5 gap-2 nexchange-second-mmm">
                          <img
                            src={icon3}
                            className="nexsec2-icon-img"
                            alt="nexchange-home"
                          />
                          <div>
                            <h5 className="nex-card-tx1">Earn</h5>
                            <p className="nex-card-tx2 mb-0">
                              Effortlessly stake and watch your daily earnings grow.
                            </p>
                          </div>
                        </div>

                        <div className="d-flex gap-lg-5 gap-2 nexchange-second-mmm">
                          <img
                            src={icon4}
                            className="nexsec2-icon-img"
                            alt="nexchange-home"
                          />
                          <div>
                            <h5 className="nex-card-tx1">Prime</h5>
                            <p className="nex-card-tx2 mb-0">
                              Maximize your returns with simple, one-click staking.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 col-lg-5 col-6 mx-auto col-md-4"
                      data-aos="zoom-in-up"
                    >
                      <div className="mobile-outline">
                        <video
                          width="100%"
                          height="100%"
                          autoPlay={true}
                          loop={true}
                          muted
                          className="video_contain-2"
                          src={Mobileone}
                        ></video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Moon />

      <section className="nexchange-third-section pb-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="text-center">
              <h3 className="nexchange-tx1">Trade Anywhere, Anytime</h3>
            </div>
            <div className="col-lg-8 col-md-12 pt-lg-5">
              <a>
                <img src={icon5} alt="nexchange-home" style={{ width: "15px", height: "15px" }} />{" "}
                <span className="nex-card-tx2">
                  BitNevex Lists RWN/USDT Trading Pair
                </span>
              </a>
              <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1">

                <div className="col pt-5">
                  <div className="card h-100" data-aos="fade-up">
                    <div className="card-body">
                      <div className="d-flex flex-md-row justify-content-between gap-2 flex-column-reverse">
                        <div>
                          <h5 className="nex-card-tx3">Premium Digital Asset Investment Experience</h5>
                          <p className="nex-card-tx4">
                            Accessible to all, we provide comprehensive investment services in derivatives and
                            finance, built on spot trading.
                          </p>
                          <button className="nex-know-btn">know more</button>
                        </div>
                        <img src={cardicon} className="nex-sec3-cardicon" alt="nexchange-home" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col pt-5">
                  <div className="card h-100" data-aos="fade-up">
                    <div className="card-body">
                      <div className="d-flex flex-md-row justify-content-between gap-2 flex-column-reverse">
                        <div>
                          <h5 className="nex-card-tx3">Diversified Trading Options</h5>
                          <p className="nex-card-tx4">
                            Discover and engage with a broad spectrum of trading products, including spot,
                            leverage, futures, ETFs, indices, and more to enhance your cryptocurrency
                            experience.
                          </p>
                          <button className="nex-know-btn">know more</button>
                        </div>
                        <img src={cardicon} className="nex-sec3-cardicon" alt="nexchange-home" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col pt-5">
                  <div className="card h-100" data-aos="fade-up">
                    <div className="card-body">
                      <div className="d-flex flex-md-row justify-content-between gap-2 flex-column-reverse">
                        <div>
                          <h5 className="nex-card-tx3">Advanced Security and Risk Management</h5>
                          <p className="nex-card-tx4">
                            Industry-leading technology and round-the-clock monitoring ensure comprehensive
                            protection for your assets
                          </p>
                          <button className="nex-know-btn">know more</button>
                        </div>
                        <img src={cardicon} className="nex-sec3-cardicon" alt="nexchange-home" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col pt-5">
                  <div className="card h-100" data-aos="fade-up">
                    <div className="card-body">
                      <div className="d-flex flex-md-row justify-content-between gap-2 flex-column-reverse">
                        <div>
                          <h5 className="nex-card-tx3">Advanced Tools for the Expert Trader</h5>
                          <p className="nex-card-tx4">
                            Harness powerful analytics with Coinbase’s trusted security, offering sophisticated
                            charting, real-time order books, and deep liquidity across hundreds of markets for an
                            unparalleled trading experience.
                          </p>
                          <button className="nex-know-btn">know more</button>
                        </div>
                        <img src={cardicon} className="nex-sec3-cardicon" alt="nexchange-home" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="nexchange-fourth-section py-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10 nex-sec4-card" data-aos="zoom-in-up">
              <div className="d-flex flex-lg-row flex-column-reverse justify-content-between">
                {/* <div className="">
                  <span className="nex-card-tx1">Spot</span>
                  <span className="ps-md-5 ps-2 nex-card-tx1">Futures</span>
                </div> */}
                <ul
                  class="nav nav-pills gap-md-4 gap-1"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link bg-transparent nex-card-tx1 active"
                      id="pills-spot-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-spot"
                      type="button"
                      role="tab"
                      aria-controls="pills-spot"
                      aria-selected="true"
                    >
                      Spot
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link bg-transparent nex-card-tx1"
                      id="pills-fut-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-fut"
                      type="button"
                      role="tab"
                      aria-controls="pills-fut"
                      aria-selected="false"
                    >
                      Future
                    </button>
                  </li>
                </ul>
                <a className="nex-card-tx2 opacity-100 pe-3 ms-auto" href="#">
                  view more
                  <MdKeyboardArrowRight className="nex-card-tx3" />
                </a>
              </div>
              <div className=" pt-3">
                <ul
                  class="nav nav-pills mb-3 gap-md-4 gap-1"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Top Gainers
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Hot coin
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Prime
                    </button>
                  </li>
                </ul>
              </div>
              <div className="nex-t-spot-f-width-scroll">
                <div className="nex-t-spot-f-width">
                  <DataTable columns={columns} data={data} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="nexchange-fifth-section py-5">
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="text-center">
                <h3 className="nexchange-tx1 nex3-top">Get started in a few minutes</h3>
                <p className="nex-card-tx2 mb-0 opacity-100">
                  Our mission is to safeguard each user's privacy. We are committed to protecting you
                  from unwanted tracking
                </p>
                <Sun />
                <h3 className="nex-card-tx3 text-uppercase">
                  Unlimited possibilities
                </h3>
              </div>
            </div>
            <div className="col-lg-10 col-md-11">
              <div
                className="row row-cols-lg-5 row-cols-md-5 row-cols-1 pt-5 justify-content-evenly"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                {cardsData.map((card, index) => (
                  <div className="col pt-3 pt-md-0 pt-lg-0" key={index}>
                    <div className="card p-3 mx-auto border-0">
                      <div>
                        <img
                          src={card.src}
                          className="nex-sec5-icon"
                          alt="nexchange-home"
                        />
                        <p className="nex-card-tx2 mb-0 opacity-100">
                          {card.text}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="nexchange-sixth-section my-5">
        <div className="container-fluid">
          <div className="row justify-content-center position-relative">
            <div
              className="col-lg-10"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div className="card border-0">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-6">
                    <div>
                      <h3 className="nexsec6-tx1 nex3-top">
                        trade anytime, anywhere.
                      </h3>
                      <p className="nexsec6-tx2">
                        open new positions instantly, whether it’s on BitNevex app
                        or web.{" "}
                      </p>
                    </div>
                    <div className="d-flex d-md-flex align-items-center gap-4">
                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100px" }}
                        value="https://bitnevex.com/"
                        viewBox={`0 0 256 256`}
                      />
                      {/* <img
                        src={scan}
                        className="nex-sec6-icon1"
                        alt="nexchange-home"
                      /> */}
                      <div>
                        <h3 className="nex-card-tx1 nex3-top text-uppercase mb-2 opacity-75">
                          scan now to download
                        </h3>
                        <h3 className="nex-card-tx1 nex3-top text-uppercase opacity-75">
                          ios & android
                        </h3>
                      </div>
                    </div>
                    <div className="pt-4 gap-md-4 d-flex d-md-flex flex-wrap">
                      <Link to="/coming-soon">
                        <img
                          src={appstore}
                          className="nex-sec6-icon img-fluid py-3 py-lg-0 py-md-0"
                          alt="nexchange-home"
                        />
                      </Link>
                      <Link to="/coming-soon">
                        <img
                          src={playstore}
                          className="nex-sec6-icon img-fluid py-3 py-lg-0 py-md-0"
                          alt="nexchange-home"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="nexsec6-card-fiximage"
                      data-aos="fade-up"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-duration="3000"
                    >
                      <img
                        src={phone2}
                        className="nex-full-img"
                        alt="nexchange-home"
                      />
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="nexchange-seventh-section py-5">
        <div className="container-fluid">
          <div className="row justify-content-center" data-aos="zoom-in-down">
            <div className="col-lg-8" >
              <h3 className="nexchange-tx1 py-5">Faq</h3>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Introduction to Futures Credit</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>What is a BitNevex?</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>A Beginners Guide to Earn</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Join Us on BitNevex Live</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>How to Buy Cryptocurrency on BitNevex</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>How to Buy Cryptocurrency on BitNevex</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

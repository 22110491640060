import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContextData } from "../core/context/index";
import Config from "../core/config";
import { makeRequest } from "../core/services/v1/request";
import { toast } from "../core/lib/toastAlert";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap"
import DataTable from 'react-data-table-component';
import { clickNavigate } from "../core/helper/common";

export default function DatatableWallet(props) {
  const navigate = useNavigate();
  const { myProfile, setUserProfile } = useContextData();
  const {
    walletData: propswalletData = [],
    balShow = false,
  } = props;
  const [filterText, setFilterText] = useState('');
  const [walletData, setWalletData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [fromAccount, setFromAccount] = useState("Main Wallet");
  const [toAccount, setToAccount] = useState("P2P Wallet");
  const [options, setOptions] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [estimatedValue, setEstimatedValue] = useState({});

  let columns = [];
  let filteredItems = []
  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    if (response) {
      const {
        status = false,
        data = [],
        estimateINR = 0,
        estimateUSD = 0,
      } = response;
      setWalletData(data);
      setEstimatedValue({
        estimateINR,
        estimateUSD,
      });
    }
  }
  useEffect(() => {
    getWalletCurrency()
  }, [props]);
  if (walletData) {
    filteredItems = walletData.filter(
      item => item.currencyName && item.currencyName.toLowerCase().includes(filterText.toLowerCase()) ||
        (item.currencySymbol && item.currencySymbol.toLowerCase().includes(filterText.toLowerCase())),
    );
    columns = [
      {
        name: 'Symbol',
        selector: row => (<><img src={row.image} class="color-white pr-top smallSize" /> {row.currencySymbol}</>),
        sortable: false,
      },
      {
        name: 'Name',
        selector: row => row.currencyName,
        sortable: true,
      },
      {
        name: 'Main Wallet Balance',
        selector: row => (
          <>
            {balShow ? Number(row.balance) > 0 ? parseFloat(Number(row.balance).toFixed(8)) : '0.0000' : "******"}
          </>
        ),
        sortable: true,
      },
      {
        name: 'Eq.USD Value',
        selector: row => (
          <>
            {
              balShow ?
                row && (row.USDvalue * row.balance > 0) ? parseFloat((Number(row.USDvalue) * Number(row.balance)).toFixed(8))
                  : '0.0000'
                : '******'
            }
          </>
        ),
        sortable: true,
      },
      {
        name: 'USD Value',
        selector: row => (
          <>
            {
              balShow ?
                row && row.USDvalue ? parseFloat(Number(row.USDvalue).toFixed(8))
                  : '0.0000'
                : '******'
            }
          </>
        ),
        sortable: true,
      },
      {
        name: "Action",
        width: "350px",
        backgroundColor: "transparent !important",

        selector: row => (
          <>
            {(row.transferEnable > 0 || row.transferperpetualEnable > 0) && (
              <button
                className="unset-unselected btnfont mb-2"
                onClick={() => transferFund(row)}
              // disabled
              >
                Transfer
              </button>
            )}
            {row.depositEnable == true ? (
              <button
                className="unset-unselected btnfont mb-2"
                onClick={() =>
                  clickNavigate_call({
                    type: "deposit",
                    row,
                    url: row.currencySymbol == "PI" ? "/deposit/Pi" :
                      "/deposit/" +
                      row.curnType.toLowerCase() +
                      "/" +
                      row.currencySymbol,
                  })
                }
                disabled={row.depositEnable ? false : true}
              >
                Deposit
              </button>
            ) : (
              ""
            )}
            {row.withdrawEnable == true ? (
              <button
                className="unset-unselected btnfont mb-2"
                onClick={() =>
                  clickNavigate_call({
                    type: "withdraw",
                    row,
                    url: row.currencySymbol.toLowerCase() == "pi" ?
                      "/withdraw/" +
                      "crypto" +
                      "/" +
                      "PI" :
                      "/withdraw/" +
                      row.curnType.toLowerCase() +
                      "/" +
                      row.currencySymbol,
                  })
                }
                disabled={row.withdrawEnable ? false : true}
              >
                Withdraw
              </button>
            ) : (
              ""
            )}
            {
              row.firstTradePair ?
                row.firstTradePair.pair ? (
                  <button
                    className="unset-unselected btnfont mb-2"
                    onClick={() =>
                      clickNavigate_call({
                        type: "trade",
                        row,
                        url: "/spot/" + row.firstTradePair.pair,
                      })
                    }
                  >
                    Trade
                  </button>
                ) : (
                  ""
                )
                :
                ''
            }
          </>
        ),
        sortable: false,
      }
    ]
    if (options == 1) {
      columns.splice(3, 0, {
        name: 'Crypto Loan Balance',
        selector: row => row.cryptoLoanAmount,
        sortable: true,
      })
    } else if (options == 2) {
      columns.splice(3, 0, {
        name: 'Game Wallet Balance',
        selector: row => row.gamePredictionAmount,
        sortable: true,
      })
    } else if (options == 3) {
      columns.splice(3, 0, {
        name: 'P2P Balance',
        selector: row => row.p2pAmount,
        sortable: true,
      })
    } else if (options == 4) {
      columns.splice(3, 0, {
        name: 'USD-M Balance',
        selector: row => row.perpetualAmount,
        sortable: true,
      })
    } else if (options == 5) {
      columns.splice(3, 0, {
        name: 'Simple Earn Balance',
        selector: row => row.simpleEarnAmount,
        sortable: true,
      })
    }
    else if (options == 6) {
      columns.splice(3, 0, {
        name: 'Options Wallet Balance',
        selector: row => row.optionsGameAmount,
        sortable: true,
      })
    }
  }
  const transferFund = (data) => {
    setOpen(true);
    // toast({ type: "warn", message: "There is only Spot Wallet at this time. unable to move to a different wallet." });
    // return
    if (data) setCurrency(data);
  };
  const clickNavigate_call = (data = {}) => {
    const resp = clickNavigate(data, myProfile);
    if (resp && resp.status && resp.url) {
      navigate(resp.url);
    }
  };
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      // use the selector to resolve your field names by passing the sort comparators
      // console.log("a-->", selector(a));
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      }
    });
  };
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: yup.object({
      amount: yup
        .number()
        .required("amount is required"),
    }),
    onSubmit: async (values) => {
      let balance =
        fromAccount == "Main Wallet"
          ? currency.balance
          : fromAccount == "USD-M Wallet"
            ? currency.perpetualAmount
            : fromAccount == "Loan Wallet"
              ? currency.cryptoLoanAmount
              : fromAccount == "Game Wallet"
                ? currency.gamePredictionAmount
                : fromAccount == "Simple Earn Wallet"
                  ? currency.simpleEarnAmount
                  : fromAccount == "Options Wallet"
                  ? currency.optionsGameAmount
                  : currency.p2pAmount;

      let amount = values.amount;
      if (balance >= amount) {
        const body = {
          amount: amount,
          currencyId: currency.currencyId,
          fromAccount: fromAccount,
          toAccount: toAccount,
        };
        const params = {
          url: `${Config.V1_API_URL}wallet/submitTransfer`,
          method: "POST",
          body,
        };
        setisLoading(true);
        const response = await makeRequest(params);
        let type = "error";
        setisLoading(false);
        if (response.status) {
          type = "success";
          handleClose();
          getWalletCurrency()
        }
        toast({ type, message: response.message });
      } else {
        toast({
          type: "error",
          message: "Insufficient Balance On " + fromAccount,
        });
      }
    },
  });
  const handleClose = () => {
    setOpen(false);
    formik.values.amount = "";
  };
  const fromWallet = (e) => {
    setFromAccount(e.target.value);
    setToAccount(
      e.target.value == "Main Wallet" ? "P2P Wallet" : "Main Wallet"
    );
  };
  const toWallet = (e) => {
    setToAccount(e.target.value);
  };
  return (
    <>
      <h4>Wallet</h4>
      <Row>
        <Col sm="12" md="6">
          <Form>
            <FormGroup>
              <Label for="exampleSelect">Select wallet to view balance</Label>
              <Input type="select" onClick={(event) => setOptions(event.target.value)} name="select" id="exampleSelect">
                <option>Spot Wallet</option>
                {
                  Config.CRYPTO_LOAN_STATUS == "Enable" ?
                  <option value={1}>Crypto Loan Balance</option>
                  :
                  ''
                }
                {
                  Config.BEAR_AND_BULL_STATUS == "Enable" ?
                    <option value={2}>Game Wallet Balance</option>
                    :
                    ''
                }
                {
                  Config.P2P_STATUS == "Enable" ?
                    <option value={3}>P2P Balance</option>
                    :
                    ''
                  }
                {
                  Config.DERIVATIVES_STATUS == "Enable" ?
                  <option value={4}>USD-M Balance</option>
                  :
                  ''
                }
                {
                  Config.SIMPLEEARN_STATUS == "Enable" ?
                    <option value={5}>Simple Earn Balance</option>
                    :
                    ''
                }
                {
                  Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable" ?
                    <option value={6}>Options Wallet Balance</option>
                    :
                    ''
                }
              </Input>
            </FormGroup>
          </Form>
        </Col>
        <Col sm="12" md="6">
          <Form>
            <FormGroup>
              <Label for="exampleSelect">Search</Label>
              <Input
                id="search"
                type="text"
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </FormGroup>
          </Form>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        persistTableHead
        sortFunction={customSort}
      />
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer {currency.currencySymbol}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row justify-content-center align-items-center ">
              <div className="row mt-4">
                <span className="phonenumber-change-text-2">
                  Internal Transfer are free on {Config.SITENAME}
                </span>
                <select
                  className="f-control f-dropdown"
                  placeholder="Select"
                  value={fromAccount}
                  onChange={fromWallet}
                >
                  <option value="Main Wallet">Main Wallet</option>
                  <option value="P2P Wallet">P2P Wallet</option>
                  {Config.DERIVATIVES_STATUS == "Enable" ? <option value="USD-M Wallet">USD-M Wallet</option> : ""}
                  {Config.CRYPTO_LOAN_STATUS == "Enable" ? <option value="Loan Wallet">Crypto Loan Wallet</option> : ""}
                  {Config.SIMPLEEARN_STATUS == "Enable" ? <option value="Simple Earn Wallet">Simple Earn Wallet</option> : ""}
                  {Config.BEAR_AND_BULL_STATUS == "Enable" ? <option value="Game Wallet">Prediction Game Wallet</option> : ""}
                  {Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable" ? <option value="Options Wallet">Options Game Wallet</option> : ""}
                </select>
              </div>
              <div className="row mt-4">
                <select
                  className="f-control f-dropdown"
                  placeholder="Select"
                  value={toAccount}
                  onChange={toWallet}
                >
                  <option value={fromAccount == "Main Wallet" ? "P2P Wallet" : "Main Wallet"} >
                    {fromAccount == "Main Wallet" ? "P2P Wallet" : "Main Wallet"}
                  </option>
                  {Config.DERIVATIVES_STATUS === "Enable" ?
                    fromAccount == "Main Wallet" && (
                      <option
                        value={
                          fromAccount == "Main Wallet"
                            ? "USD-M Wallet"
                            : "Main Wallet"
                        }
                      >
                        {fromAccount == "Main Wallet"
                          ? "USD-M Wallet"
                          : "Main Wallet"}
                      </option>
                    ) : ""}
                  {Config.SIMPLEEARN_STATUS === "Enable" ?
                    fromAccount == "Main Wallet" && (
                      <option
                        value={
                          fromAccount == "Main Wallet"
                            ? "Simple Earn Wallet"
                            : "Main Wallet"
                        }
                      >
                        {fromAccount == "Main Wallet"
                          ? "Simple Earn Wallet"
                          : "Main Wallet"}
                      </option>
                    )
                    : ""
                  }
                  {Config.BEAR_AND_BULL_STATUS == "Enable" ?
                    fromAccount == "Main Wallet" && (
                      <option value={fromAccount == "Main Wallet" ? "Game Wallet" : "Main Wallet"}>
                        {fromAccount == "Main Wallet" ? "Prediction Game Wallet" : "Main Wallet"}
                      </option>
                    ) : ""}

                    {Config.OPTIONS_BEAR_AND_BULL_STATUS == "Enable" ?
                    fromAccount == "Main Wallet" && (
                      <option value={fromAccount == "Main Wallet" ? "Options Wallet" : "Main Wallet"}>
                        {fromAccount == "Main Wallet" ? "Options Game Wallet" : "Main Wallet"}
                      </option>
                    ) : ""}
                    
                </select>

              </div>
              <div className="row mt-4">
                <span className="phonenumber-change-text-2">Amount</span>
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  label="Amount"
                  id="amount"
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
                {formik.errors.amount ? (
                  <small className="invalid-amount error">
                    {formik.errors.amount}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="row mt-5">
              <span className="phonenumber-change-text-2">
                {fromAccount} Balance:{" "}
                {fromAccount == "Main Wallet"
                  ? currency.balance?.toFixed(currency.siteDecimal)
                  : fromAccount == "USD-M Wallet"
                    ? currency.perpetualAmount?.toFixed(currency.siteDecimal)
                    : fromAccount == "Loan Wallet"
                      ? currency.cryptoLoanAmount?.toFixed(currency.siteDecimal)
                      : fromAccount == "Simple Earn Wallet"
                        ? currency.simpleEarnAmount?.toFixed(currency.siteDecimal)
                        : fromAccount == "Game Wallet"
                          ? currency.gamePredictionAmount?.toFixed(currency.siteDecimal)
                          : currency.p2pAmount?.toFixed(currency.siteDecimal)}{" "}
                {currency.currencySymbol}
              </span>
              <div className="col">
                <div className="d-grid">
                  <button
                    className="add-payment-method-confirm-button"
                    type="submit"
                    disabled={isLoading}
                  >
                    Transfer Amount {isLoading == true ?
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div> : ''}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
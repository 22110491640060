import React, { useEffect, useState } from "react";
import "../../assets/style.css";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsArrowRight } from 'react-icons/bs';
import { AiFillCalendar } from 'react-icons/ai';
import DataTable from "react-data-table-component";
import Config from "../../core/config/index";
import { makeRequest } from "../../core/services/v1/request";
import { dateFormat, dateSelector } from "../../core/helper/date-format";
import notFound from "../../assets/images/file.png"
import { GoChevronLeft } from "react-icons/go";


export default function Simpleearn(props) {

  const beforeOneMonth = new Date(new Date().setDate(new Date().getDate() - 30))
  const [startDate, setStartDate] = useState(dateSelector(beforeOneMonth));
  const [endDate, setEndDate] = useState(dateSelector(new Date()));
  const [maxEndDate, setMaxEndDate] = useState("");
  const [minEndDate, setMinEndDate] = useState("")
  const [userearnhistory, setuserearnhistory] = useState([]);

  console.log({ startDate }, { endDate })

  const [symbols, setSymbol] = useState([
    { currencysymbol: "WBTC", value: "WBTC" },
    { currencysymbol: "ETH", value: "ETH" }
  ]);
  const [selectedCurrency, setSelectedCurrency] = useState('All');
  const [selectedEarnType, setSelectedEarnType] = useState('All');
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [totalDocs, setTotalDocs] = useState(0);

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-class')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    });

    const maxFormattedEndDate = dateSelector(new Date)
    setMaxEndDate(maxFormattedEndDate)
  }, []);

  useEffect(() => {
    const minFormattedEndDate = dateSelector(startDate);
    setMinEndDate(minFormattedEndDate);

  }, [startDate]);

  const columns = [
    {
      id: 1,
      name: "Asset",
      selector: (row) => row.currencySymbol
    },
    {
      id: 2,
      name: "Invest Amount",
      selector: (row) => row.investAmount,
    },
    {
      id: 3,
      name: "Locked Days",
      selector: (row) => Number(row.lockedDays) > 0 ? row.lockedDays : "Infinity"
    },
    {
      id: 4,
      name: "APR in %",
      selector: (row) => row.aprPercentage
    },
    {
      id: 5,
      name: "Stake Type",
      selector: (row) => row.type
    },
    {
      id: 6,
      name: "Date",
      selector: (row) => dateFormat(row.startingDate)
    },
    {
      id: 7,
      name: "Status",
      selector: (row) => row.simpleEarnstatus == 0 ? "Active" : "Ended"
    }
  ];

  const getHistory = async () => {
    const params = {
      url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/userAllHistory?currency=${selectedCurrency}&earnType=${selectedEarnType}&limit=${Number(rowsPerPage)}&page=${currentPage}&from=${startDate}&to=${endDate}`,
      method: "POST",
    }
    const response = await makeRequest(params);
    if (response.status) {
      setuserearnhistory(response.data);
      setTotalDocs(response.totalHistory)
    };
  };

  useEffect(() => {
    getHistory();
  }, [selectedCurrency, selectedEarnType, currentPage, rowsPerPage, startDate, endDate]);

  const resetStates = () => {
    setSelectedCurrency("All");
    setSelectedEarnType("All");
    setStartDate(dateSelector(beforeOneMonth));
    setEndDate(maxEndDate);
  };

  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="simpleearn-top-banner-section">
        <div className="container py-3">
          <div className="row align-items-center">
            <div className="col">
              <a href="/simple-earn">
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Back</span>
              </a>
            </div>
          </div>
        </div>
        <div className="simpleearn-top-value-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <p className="fs-14 mb-1">Earn</p>
                <p className="simpleearn-text-1">Simple Earn History</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container pt-5">
          <div className="row">
            <div className="col-lg-12 accountearn-staking-tabs-section">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-earn-staking-1-tab" data-bs-toggle="pill" data-bs-target="#pills-earn-staking-1" type="button" role="tab" aria-controls="pills-earn-staking-1" aria-selected="true">Locked</button>
                </li>
              </ul>
            </div>
          </div>
        </div> */}

        <div className="container my-3">
          <div className="row">
            <div className="col-lg-2">
              <span className="enter-amount-heading">Coin</span>
              <div className="f-group" style={{ margin: "0" }}>
                <select className="f-control f-dropdown" placeholder="Select" onChange={(e) => setSelectedCurrency(e.target.value)}>
                  <option value="All" selected="selected" className="bg-selection">
                    All
                  </option>
                  {symbols && symbols.map(item => (
                    <option key={item.value} value={item.currencysymbol} className="bg-selection">
                      {item.currencysymbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>


            <div className="col-lg-2">
              <span className="enter-amount-heading">Type</span>
              <div className="f-group" style={{ margin: "0" }}>
                <select className="f-control f-dropdown" placeholder="Select" value={selectedEarnType} onChange={(e) => setSelectedEarnType(e.target.value)}>
                  <option selected="selected" className="bg-selection" value="All">
                    All
                  </option>
                  <option selected="selected" className="bg-selection" value="Fixed">
                    Fixed
                  </option>
                  <option value="Flexible" className="bg-selection">
                    Flexible
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-4">
              <span className="enter-amount-heading">Date</span>
              <div className="d-flex flex-row justify-content-around align-items-center f-control-s-earn-group">
                <input type="date"
                  className="form-control p-0 border-0"
                  value={dateSelector(startDate)}
                  max={maxEndDate}
                  name="endDate"
                  id="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <BsArrowRight className="mx-3" />

                <input type="date"
                  className="form-control p-0 border-0"
                  value={dateSelector(endDate)}
                  min={minEndDate}
                  max={maxEndDate}
                  name="endDate"
                  id="endDate"
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="col-lg-1 col-6 mt-lg-0 mt-3">
              <div class="d-grid mt-lg-4">
                <button class="btn simpleearn-history-filter-1 mt-1" type="button" >Search</button>
              </div>
            </div> */}
            <div className="col-lg-1 col-6 mt-lg-0 mt-3">
              <div class="d-grid mt-lg-4">
                <button class="btn simpleearn-history-filter-2 mt-1" type="button" onClick={() => resetStates()}>Reset</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-3">
          <div className="row">
            <div className="col">

              <DataTable
                columns={columns}
                data={userearnhistory}
                pagination
                paginationServer
                persistTableHead
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalDocs}
                paginationRowsPerPageOptions={[5, 10, 15]}
                onChangeRowsPerPage={newRowsPerPage => {
                  setRowsPerPage(newRowsPerPage);
                  setCurrentPage(1); // Reset to first page when rows per page changes
                }}
                onChangePage={page => setCurrentPage(page)}
                noDataComponent={
                  <div className="text-center py-2">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                    <br />
                    <span>No Records Found</span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

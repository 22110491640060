import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.png';
import { FaInstagram, FaTwitter, FaLinkedinIn, FaDiscord, FaTelegramPlane, FaYoutube, FaMediumM } from 'react-icons/fa';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
} from "react-router-dom";
import Getx from "../../assets/new-getx-images/getx.png";
import QRcode from "../../assets/images/qr.png";
import Appstore from "../../assets/images/app-store.png";
import Googleplay from "../../assets/images/google-play.png";
import { useContextData } from '../../core/context/index';
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {
    getHomeCMS
} from '../../core/services/all.api';

import Config from "../../core/config/index";
import LawEnforcement from "../../assets/images/pdf/Regulations-Pertaining-to-Law-Enforcement-and-Lega.pdf";
import DummyQR from "../../assets/images/dummy-QR.png";
import { getCookie } from '../../core/helper/cookie';
import { makeRequest } from '../../core/services/v1/request';
import QRCode from 'react-qr-code';

const Footer = () => {
    const navigate = useNavigate();
    const { siteSettings } = useContextData();
    const [cmsDynData, setCmsDynData] = useState({});
    const [socialMediaLinks, setSocialMediaLinks] = useState({});
    const token = getCookie("userToken");
    const path = window.location.pathname.split("/").pop();

    useEffect(() => {
        getFooterCMSCall();
        getSiteSettings();
    }, []);

    const isValidHttpUrl = async (val = "") => {
        let url;
        try {
            url = new URL(val);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
        // return val.search("http");
    }

    const navigateTo = async (linkVal) => {
        if (linkVal) {
            if (await isValidHttpUrl(linkVal)) {
                window.open(linkVal);
            }
            else {
                navigate(linkVal, true);
            }
        }
    }

    async function getSiteSettings() {
        const params = {
            url: `${Config.V1_API_URL}common/siteSettings`,
            method: 'GET',
        }
        const response = (await makeRequest(params));
        // console.log("response--",response);
        if (response.status) {
            setSocialMediaLinks(response.message);
        } else {
            setSocialMediaLinks({});
        }
    }

    const getFooterCMSCall = async () => {
        const payload = { from: "footer" };
        const resp = await getHomeCMS({ payload });
        if (resp) {
            const {
                getcmsDetails = [],
                status = false
            } = resp;
            if (status) {
                let CMSList = getcmsDetails.filter((elem) => (elem.identify == "CMS"));
                const updateCmdData = {
                    CMSList: (CMSList && CMSList.length > 0) ? CMSList : []
                };
                setCmsDynData(updateCmdData);
            }
        }
    }

    return (
        <div className='home-dfd-footer-sec nexchange-footer-section footer-2-bottom-section'>
            <div className='container-fluid col-lg-10'>
                <div className='row g-4 justify-content-between'>
                    <div className='col-lg-4 col-12'>
                        <h5 className='mb-4'>BitNevex</h5>
                        <p className='dfd-footer-txt-1'>Through many of its unique properties, Bitcoin allows exciting uses that could not be covered by any previous payment system.</p>
                        <h5 className='mb-md-4 dfd-footer-txt-2 mt-5'>Get in Touch</h5>
                        <p className='dfd-footer-txt-1 mb-md-3'>Question or feedback? We’d love to hear from you.</p>
                        <div >
                            <input type="email" class="form-control" placeholder="Your email" />
                        </div>

                        <div class="social-menu-new-footer mt-5">
                            <ul className='p-0'>
                                <li><a href={socialMediaLinks?.facebookLink} target="blank" rel="noopener noreferrer"><FaFacebookF className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.twitterLink} target="blank" rel="noopener noreferrer"><FaXTwitter className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.telegramLink} target="blank" rel="noopener noreferrer"><FaTelegramPlane className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.linkedinLink} target="blank" rel="noopener noreferrer"><FaLinkedinIn className='fab' /></a></li>
                            </ul>
                            <ul className='p-0'>
                                <li><a href={socialMediaLinks?.youtubeLink} target="blank" rel="noopener noreferrer"><FaYoutube className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.instagramLink} target="blank" rel="noopener noreferrer"><FaInstagram className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.discordLink} target="blank" rel="noopener noreferrer"><FaDiscord className='fab' /></a></li>
                                <li><a href={socialMediaLinks?.mediumLink} target="blank" rel="noopener noreferrer"><FaMediumM className='fab' /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6'>
                        <h5 className='mb-4 dfd-footer-txt-2'>About Us</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a className='footer-typo-list' href='/about'>BitNevex</a>
                            <a className='footer-typo-list' href='/authenticity-check'>Authenticity Check</a>
                            <a className='footer-typo-list' href='/authenticity-check'>Authorized Payment Personnel</a>
                            <a className='footer-typo-list' href='/affiliate'>BitNevex Affiliate PROGRAM</a>
                            <a className='footer-typo-list' href=''>Whitepaper</a>
                        </div>

                        <h5 className='mb-4 dfd-footer-txt-2 mt-4'>Legal</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a className='footer-typo-list' href='/privacy-policy'>Privacy Policy</a>
                            <a className='footer-typo-list' href='/aml-policy'>AML Policy</a>
                            <a className='footer-typo-list' href='/cookie-statement'>Cookie Statement</a>
                            <a className='footer-typo-list' href='/risk-warning'>Risk Warning</a>
                            <a className='footer-typo-list' href='/terms'>Terms Of Use</a>
                            <a className='footer-typo-list' href='/refund-policy'>Refund Policy</a>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-6'>
                        <h5 className='mb-4 dfd-footer-txt-2'>Resources</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a className='footer-typo-list' href="/coin-status">Coin Status</a>
                            <a className='footer-typo-list' href="/referralprogram">Referral Program</a>
                            <a className='footer-typo-list' href="/coin-listing">List Your Token / Coin</a>
                            <a className='footer-typo-list' href="/spot/BTC_USDT">Trade</a>
                            <a className='footer-typo-list' href="/fees">Fees</a>
                            <a className='footer-typo-list' href="/launch-pad">Fundraising/crowdfunding</a>
                            <a className='footer-typo-list'
                                // href="/prediction-trade"
                                href="javascript:void(0);"
                            >Trade Contest (coming soon)</a>
                            <a className='footer-typo-list' href="/apipage">API Documentation</a>
                        </div>
                        <h5 className='mb-4 dfd-footer-txt-2 mt-4'>Support (24X7)</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a className='footer-typo-list' href="/contact-support">Help Desk</a>
                            <a className='footer-typo-list' href="/support">Submit a Ticket</a>
                            <a className='footer-typo-list' href="mailto:sabarirajan@clariscosolutions.com"  >Give Us Feedback</a>
                            <a className='footer-typo-list' href="" target='_blank'>For Law Enforcement</a>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <h5 className='mb-4 dfd-footer-txt-2'>Trade Crypto</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a className='footer-typo-list' href="/spot/BTC_INR">Buy Bitcoin in India</a>
                            <a className='footer-typo-list' href="/spot/ETH_INR">Buy Ethereum in India</a>
                            <a className='footer-typo-list' href="/spot/USDT_INR">Buy Tether in India</a>
                            <a className='footer-typo-list' href="/spot/GETX_INR">Buy GETX in India</a>
                            <a className='footer-typo-list' href="/spot/DCX_INR">Buy D-coin in India</a>
                            <a className='footer-typo-list' href="/spot/LTE_INR">Buy Litecoin in India</a>
                            <a className='footer-typo-list' href="/spot/TRX_INR">Buy Tron in India</a>
                            <a className='footer-typo-list' href="/spot/ADA_INR">Buy Cardano in India</a>
                            <a className='footer-typo-list' href="/spot/MATIC_INR">Buy Polygon in India</a>
                            <a className='footer-typo-list' href="/spot/SOL_INR">Buy Solana in India</a>
                        </div>
                    </div>
                </div>
                <div className='row g-4 justify-content-between mt-4'>
                    <div className='col-lg-12'>
                        {
                            path !== "transactionHistory" && (
                                siteSettings &&
                                <p className='dfd-footer-txt-1 text-lg-center text-sm-start '>
                                    {siteSettings?.copyRights}
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import { makeRequest } from '../../core/services/v1/request';
import Config from '../../core/config/';
import { AiOutlineSearch } from "react-icons/ai";
import { CiStar } from "react-icons/ci";
import DataTable from 'react-data-table-component';
import { toast } from '../../core/lib/toastAlert';
import { FaStar } from "react-icons/fa6";
import { useContextData } from '../../core/context';
import { Bars } from "react-loader-spinner";
import Loader from "../../core/helper/Loader";

export default function AllMarkets(props) {

  const navigate = useNavigate();
  const { myProfile, setUserProfile, p2pSettings } = useContextData();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchMarket, setSearchMarket] = useState("");
  const [socketConnection, setSocketConnection] = useState(null);
  const [marketCurrency, setMarketCurrency] = useState("USDT");
  const [markets, setMarkets] = useState([]);
  const [marketList, setMarketList] = useState([]);
  useEffect(() => {
    let socket = socketIOClient(Config.SOCKET_URL, {
      transports: ['websocket'],
      origin: '*',
    });
    let socketUnsubscribe;
    if (socket) {
      socket.on('connect', function () {
        setSocketConnection(socket);
        socketUnsubscribe = socket;
      });
      socket.on('connect_error', (err) => {
        console.log('socket connect_error', err)
      })
      socket.on('disconnect', function () {
        console.log('socket disconnected')
      });
    }
    return () => {
      if (socketUnsubscribe) {
        socketUnsubscribe.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (socketConnection != null) {
      loadSocket(marketCurrency);
    }
  }, [socketConnection, marketCurrency]);


  function loadSocket(mcur) {
    if (socketConnection != null) {
      socketConnection.on("pairResponse", data => {
        let marketsCopy = Object.assign({}, markets);
        marketsCopy && marketsCopy[mcur] && marketsCopy[mcur].length > 0 && marketsCopy[mcur].map((market, i) => {
          if (data.pair === market.pair) {
            let newPrice = data.price.toFixed(data.decimalValue);
            let oldData = marketsCopy[mcur].filter((market) => market.pair == data.pair)[0];
            let oldIndex = marketsCopy[mcur].findIndex((market) => market.pair == data.pair);
            let oldPrice = oldData.price.toFixed(oldData.decimalValue);
            if (newPrice != oldPrice) {
              marketsCopy[mcur][oldIndex].price = data.price;
              marketsCopy[mcur][oldIndex].lastPrice = data.lastPrice;
              marketsCopy[mcur][oldIndex].change = data.change;
              marketsCopy[mcur][oldIndex].oldPrice = oldData.price;
            }
          }
          if (i === marketsCopy[mcur].length - 1) {
            setMarkets(marketsCopy);
          }
        })
      });
      socketConnection.on("marketPairs", async (marketData) => {
        // if (myProfile && myProfile._id != "" && typeof myProfile._id == 'string') {
        //   setMarketList(marketData)
        // } else {
        // const withoutFav = marketData?.filter((data) => data.currency !== "Fav");
        setMarketList(marketData)
        // };
      });

      socketConnection.on("marketPairData", async (marketDatas) => {
        let marketValuesCopy = Object.assign({}, markets);
        marketValuesCopy = marketDatas;
        setMarkets(marketValuesCopy);
      })
    }
  };


  const filteredItems = markets.filter((item) => {
    const quotePair = item.pair?.split("_")[1];
    let includesMarketCurrency
    if (searchMarket == "") {
      includesMarketCurrency = quotePair && quotePair.includes(marketCurrency);
    } else {
      includesMarketCurrency = item.pair && quotePair && item.pair.toLowerCase().includes(searchMarket.toLowerCase()) && quotePair.includes(marketCurrency);;
    }
    return includesMarketCurrency
  });

  const tableColumns = [
    {
      name: '',
      textAlign: 'center',
      width: "70px",
      selector: row => {
        const userFavPair = JSON.parse(localStorage.getItem("userFavPairs")) || [];
        const userFav = userFavPair.some((pair) => pair == row.pair)
        return (
          myProfile == null ?
            ''
            :
            userFav ?
              <FaStar style={{ fontSize: "20px", color: "blue", cursor: "pointer" }} onClick={() => unFavourite(row.pair)} /> :
              <CiStar style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => addFavourite(row.pair)} />
        )
      },
      sortable: false,
    },
    {
      name: 'Pair',
      textAlign: 'center',
      selector: row => (<><span className='tb-img'><img src={row.fromCurrency.image} alt={Config.SITENAME} style={{ height: '23px', width: '23px' }} /></span>{" "}{row.pair.split('_').join('/')}</>),
      sortable: false,
    },
    {
      name: 'Last Price',
      selector: row => (row.price).toFixed(row.decimalValue),
      sortable: true,
    },
    {
      name: '24h Change',
      selector: row => (
        <span
          className={
            row.changePer > 0
              ? "color-green"
              : row.changePer === 0
                ? ""
                : "color-darkpink"
          }
        >
          {
            row.changePer === 0
              ? '0.0000'
              : row.changePer > 0
                ? "+" + row.changePer.toFixed(row.totalDecimal) + "%"
                : row.changePer.toFixed(row.totalDecimal) + "%"
          }
        </span>
      ),
      sortable: true,
    },
    {
      name: '24h High',
      selector: row => row.high > 0 ? row.high.toFixed(row.decimalValue) : '0.0000',
      sortable: true,
    },
    {
      name: '24h Low',
      selector: row => row.low > 0 ? row.low.toFixed(row.decimalValue) : '0.0000',
      sortable: true,
    },
    {
      name: '24h Volume',
      selector: row => row.volume_fromCur > 0 ? row.volume_fromCur.toFixed(row.decimalValue) : '0.0000',
      sortable: true,
    },
    {
      name: 'Trade',
      selector: row => (<><button type="button" className="market-trade-button" onClick={() =>
        navigate("/spot/" + row.pair)}>Trade</button></>),
      sortable: false,
    }
  ]
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        };
        return direction === 'desc' ? comparison * -1 : comparison;
      };
    });
  };
  const addFavourite = async (symbol) => {
    try {
      let userFavPairs = JSON.parse(localStorage.getItem("userFavPairs")) || [];
      if (!userFavPairs.includes(symbol)) {
        userFavPairs.push(symbol);
        localStorage.setItem("userFavPairs", JSON.stringify(userFavPairs));
        toast({ type: "success", message: "Pair added to favourite list" });
      } else {
        toast({ type: "info", message: "Pair is already in the favourite list" });
      };
    } catch (error) {
      console.log('error', error)
      toast({ type: "error", message: "Something Went Wrong!" })
    };
  };
  const unFavourite = async (symbol) => {
    try {
      let userFavPairs = JSON.parse(localStorage.getItem("userFavPairs"));
      if (userFavPairs.includes(symbol)) {
        const index = userFavPairs.indexOf(symbol);
        if (index > -1) {
          userFavPairs.splice(index, 1);
        }
        localStorage.setItem("userFavPairs", JSON.stringify(userFavPairs));
        toast({ type: "success", message: "Pair Remove from you'r favourite list" });
      } else {
        toast({ type: "info", message: "This is not you'r fav pair" });
      };
    } catch (error) {
      toast({ type: "error", message: "Something Went Wrong!" })
    };
  };
  return (
    <div>
      <div className="d-flex flex-lg-row flex-column g-4">
        <div className='top-banner-third-section mb-3'>
          <ul className="nav nav-pills market-trade-tabs" id="pills-tab" role="tablist">
            <select className='form-select ' onClick={(e) => {
              setMarketCurrency(e.target.value);
              setSearchMarket("");
            }}>
              {
                marketList.map((data) => {
                  return (
                    <>
                      <option value={data.currency}>{data.currency}</option>
                    </>
                  )
                })
              }
            </select>
          </ul>
        </div>
        <div className="ms-auto me-lg-3 me-3 mb-3">
          <div className="input-group markets-search-group-section">
            <button type="submit" className="input-group-text"><AiOutlineSearch /></button>
            <input type="text" name="search" className="form-control" placeholder="Search Pair Name" autoComplete='off'
              value={searchMarket}
              onChange={(event) => setSearchMarket(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col pb-5">
          <div className="market-place-table-section">
            <DataTable
              columns={tableColumns}
              data={filteredItems}
              pagination
              paginationResetDefaultPage={resetPaginationToggle}
              persistTableHead
              sortFunction={customSort}
              noDataComponent={
                markets.length > 0 ?
                  filteredItems.length > 0 ?
                    null : <p className='text-alighn-center mt-3'>No Records Found!</p> : <Loader />
              }
            />
          </div>
        </div>
      </div>
    </div >
  )
}
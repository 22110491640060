import React, { useEffect, useRef, useState } from "react";
import "../../assets/style.css";
import NavbarOne from "../../Pages/siteTheme/NavbarOne";
import Footer from "../../Pages/siteTheme/Footer";
import { BsArrowRight } from "react-icons/bs";
import { HiSpeakerphone } from "react-icons/hi";
import { AiFillEye, AiOutlineSearch, AiFillInfoCircle, AiFillEyeInvisible, } from "react-icons/ai";
import { BiCaretDown } from "react-icons/bi";
import { BsXCircleFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import Config from "../../core/config/index";
import { useContextData } from "../../core/context/index";
import axios from "axios";
import { toast } from "../../core/lib/toastAlert";
import { makeRequest } from "../../core/services/v1/request";
import { getCookie, setCookie } from "../../core/helper/cookie";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/images/file.png"
import { MdOutlineCleanHands } from "react-icons/md";

export default function Simpleearn(props) {
  const navigate = useNavigate();
  const { myProfile } = useContextData();
  const [earnType, setEarnType] = useState("Fixed");
  const [stakeCurrencies, setStakeCurrencies] = useState([]);
  const [subscribeModal, setSubscribeModal] = useState({});
  const [rewardsapr, setrewardsapr] = useState([]);
  const [userbalance, setuserbalance] = useState(0);
  const [investAmount, setInvestAmount] = useState("");
  const [termsAndCond, setTermsAndCond] = useState("FALSE");
  const [expDate, setExpDate] = useState("");
  const [estimatedValue, setEstimatedValue] = useState({});
  const [balShow, setBalShow] = useState(getCookie("userBalShow") ? getCookie("userBalShow") : false);
  const [message, setMessage] = useState('');
  const [aprPercentage, setAprPercentage] = useState(0);
  const [selectedAPR, setSelectedAPR] = useState({});
  const [stakeId, setStackId] = useState(null);
  const [aprIdx, setAprIdx] = useState(0);
  const [innerSubModelIdx, setInnerSubModelIdx] = useState(0);
  const subModelCloseRef = useRef();
  const currentDate = new Date();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const [simpleEarnWallet, setWalletBalance] = useState({});

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCurrencies, setTotalCurrencies] = useState(0);

  const balShowHideCall = () => {
    if (balShow) {
      setCookie("userBalShow", false);
      setBalShow(false);
    } else {
      setCookie("userBalShow", true);
      setBalShow(true);
    }
  };

  console.log("simpleEarnWallet", simpleEarnWallet);


  useEffect(() => {
    const getWalletBalance = async () => {
      try {
        const params = {
          url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/simpleEarnWallet`,
          method: "POST",
        };
        const response = await makeRequest(params);
        if (response.status) {
          setWalletBalance(response.simpleEarnWallet);
        }
      } catch (error) {
        console.log("getWalletBalance", error);
      };
    };
    getWalletBalance();
  }, []);

  async function getWalletCurrency() {
    const params = {
      url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
      method: "GET",
    };
    const response = await makeRequest(params);
    console.log("response==>", response)
    if (response) {
      const {
        estimateINR = 0,
        estimateUSD = 0,
        estimateSimpleEarnUSD = 0,
      } = response;
      setEstimatedValue({
        estimateINR,
        estimateUSD,
        estimateSimpleEarnUSD,
      });
    }
  }

  const fixedEarnColumns = [
    {
      id: 0,
      name: "symbol",
      selector: (row) => <img className="simpleearn-table-coin-img" src={row.currencyimage} />,
    },
    {
      id: 1,
      name: "Coin",
      selector: (row) => row.currencysymbol,
    },
    {
      id: 2,
      name: "APR",
      selector: (row) => selectedAPR[row._id]?.apr || "",
    },
    {
      id: 3,
      name: "Duration(Days)",
      selector: (row) => {
        const rewardsApr = row.rewardsapr;
        return (
          <ul
            class="simpleearn-table-tabs-duration nav nav-pills gap-2"
            id="pills-tab"
            role="tablist"
          >
            {rewardsApr.map((ids, index) => (
              ids.idx = index,
              <li className="nav-item" role="presentation" key={index} onClick={() => setAprIdx(index)}>
                <button
                  className={index === 0 ? "nav-link active" : "nav-link"}
                  role="tab" type="button"
                  data-bs-toggle="pill"
                  id={`pills-duration${index + 1}-tab`}
                  data-bs-target={`#pills-duration${index + 1}`}
                  aria-controls={`pills-duration${index + 1}`}
                  aria-selected="false"
                  onClick={() => {
                    setSelectedAPR((prevData) => ({ ...prevData, [row._id]: ids }));
                    handleChangeAPR(ids.apr, ids.days)
                  }}
                >
                  {ids.days}
                </button>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      id: 4,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn-simpleearn-filter-table"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => subscribeearn(row)}
          >
            Subscribe
          </button>
        </div>
      ),
    },
  ];
  const flexibleEarnColumn = [
    {
      id: 0,
      name: "symbol",
      selector: (row) => <img className="simpleearn-table-coin-img" src={row.currencyimage} />,
    },
    {
      id: 1,
      name: "Coin",
      selector: (row) => row.currencysymbol,
    },
    {
      id: 2,
      name: "Earn Type",
      selector: (row) => "Flexible",
    },
    {
      id: 3,
      name: "APR Percentage",
      selector: (row) => {
        return (
          row.aprPercentage
        )
      },
    },
    {
      id: 4,
      name: "Action",
      selector: (row) => (
        <div>
          <button
            type="button"
            class="btn-simpleearn-filter-table"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => subscribeearn(row)}
          >
            Subscribe
          </button>
        </div>
      ),
    },
  ];

  const subscribeearn = async (subsc) => {
    setStackId(subsc._id);
    setSubscribeModal(subsc);
    if (subsc.type === "Fixed") {
      const currentDate = new Date()
      const expireDate = currentDate.setDate(currentDate.getDate() + selectedAPR[subsc._id].days)
      setAprPercentage(selectedAPR[subsc._id].apr);
      setExpDate(expireDate);
      setrewardsapr(subsc.rewardsapr);
      setInnerSubModelIdx(selectedAPR[subsc._id].idx)
    } else {
      setAprPercentage(subsc.aprPercentage);
    }
    const userdetails = {
      currencyId: subsc.currencyId,
    };
    const getuserbal = await axios.post(
      `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/getuserbal`,
      userdetails
    );
    const usramount = (getuserbal.data.balance?.simpleEarnAmount)?.toFixed(8);
    setuserbalance(usramount);
  };

  const handleChangeAPR = async (apr, days) => {
    const cur = new Date
    const expireDate = cur.setDate(cur.getDate() + days)
    setAprPercentage(apr);
    setExpDate(expireDate);
  };

  const clickinputbox = async () => {
    if (termsAndCond == "FALSE") {
      setTermsAndCond("TRUE");
    } else {
      setTermsAndCond("FALSE");
    }
  };

  useEffect(() => {
    const getCurrencyList = async () => {
      try {
        const params = {
          url: `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/stakingCurrencies?earnType=${earnType}&searchQuery=${message}&limit=${rowsPerPage}&page=${currentPage}`,
          method: "GET"
        };
        const response = await makeRequest(params);
        if (response.status) {
          setStakeCurrencies(response.data);
          setTotalCurrencies(response.totalCurrencies);
          const tableData = response.data;
          if (Array.isArray(tableData)) {
            tableData.forEach((row) => {
              if (row.rewardsapr && row.rewardsapr.length > 0) {
                setSelectedAPR((prev) => ({
                  ...prev,
                  [row._id]: row.rewardsapr[0]
                }));
              };
            });
          };
        };
      } catch (error) {
        console.log("Can't Get Simpleearn Currencies list");
      };
    };
    getCurrencyList();
  }, [message, earnType, rowsPerPage, currentPage]);

  const handleClick = () => {
    setMessage('');
    window.location.reload();
  };

  const confirmstake = async () => {
    if (investAmount == "") {
      let type = "error";
      return toast({ type, message: "Please enter your amount" });
    } else if (termsAndCond == "FALSE") {
      let type = "error";
      return toast({ type, message: "Agree to Terms and Conditions" });
    } else if (investAmount < subscribeModal.minAmount) {
      let type = "error";
      return toast({ type, message: `Minimum amount should be ${subscribeModal.minAmount}` });
    } else if (investAmount > subscribeModal.maxAmount) {
      let type = "error";
      return toast({ type, message: `Maximum amount should be ${subscribeModal.maxAmount}` });
    } else {
      let userdetails = {
        investAmount: investAmount,
        stakeId: stakeId,
        aprIdx: aprIdx
      };
      if (userdetails) {
        const response = await axios.post(
          `${Config.SIMPLEEARN_V1_API_URL}simpleEarn/stackSimpleEarn`,
          userdetails
        );
        console.log("response", response)
        if (response.data.status) {
          let type = "success";
          toast({ type, message: response.data.message });
          if (subModelCloseRef && subModelCloseRef.current) {
            subModelCloseRef.current.click();
          };
          navigate("/account-earn");
        } else {
          let type = "error";
          toast({ type, message: response.data.message });
        }
      }
    }
  };

  useEffect(() => {
    getWalletCurrency();
  }, [myProfile, earnType]);
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="simpleearn-top-banner-section finance-active-nav-class">
        <div className="simpleearn-top-value-section">
          <div className="container">
            <div className="row align-items-center g-4">
              <div className="col-lg-8">
                <div>
                  <p className="simpleearn-text-1">Simple Earn</p>
                  <p className="simpleearn-text-2 mb-4">
                    The simple way to Deposit & Earn
                  </p>
                  <button class="accountearn-all-assest border-0" onClick={() => navigate("/account-earn")}>History</button>
                  <button class="accountearn-all-assest border-0 ms-2" data-bs-toggle="modal" data-bs-target="#whatissimpleearn" >What is Simple Earn?</button>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="card">
                  <div className="d-flex flex-row">
                    <div className="ms-auto">
                      {balShow ? (
                        <AiFillEye className="cursor-pointer" onClick={() => balShowHideCall()} />
                      ) : (
                        <AiFillEyeInvisible className="cursor-pointer" onClick={() => balShowHideCall()} />
                      )}
                    </div>

                  </div>
                  <div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '20px' }}>
                        <p className="simpleearn-text-5 mb-1">Total Balance</p>
                        {balShow ? (
                          <b className="border-dot password-text-44">
                            {simpleEarnWallet.earnAmountInUSD > 0
                              ? simpleEarnWallet.earnAmountInUSD.toFixed(6)
                              : 0}{" "}
                            $
                          </b>
                        ) : (
                          <b className="password-text-44">******</b>
                        )}
                      </span>
                      <span>
                        <p className="simpleearn-text-5 mb-1">Cumulative Profit</p>
                        {balShow ? (
                          <b className="password-text-44">
                            ≈ $
                            {
                              simpleEarnWallet.walletProfit &&
                                simpleEarnWallet.walletProfit.totalInUSD
                                && simpleEarnWallet.walletProfit.totalInUSD > 0 ?
                                parseFloat(simpleEarnWallet.walletProfit.totalInUSD).toFixed(6) : '0.00'
                            }
                          </b>
                        ) : (
                          <b className="password-text-44">******</b>
                        )}
                      </span>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-lg-5 pb-lg-0 py-4 simpleearn-top-filter-section">
          <div className="row g-2 justify-content-end">
            <div className="col-lg-4 col-12">
              <div class="input-group simpleearn-top-filter-srch">
                <span
                  class="input-group-text input-group-text-1 border-0"
                  id="basic-addon1"
                >
                  <AiOutlineSearch />
                </span>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />

                <span
                  class="input-group-text input-group-text-2 border-0"
                  id="basic-addon1"
                  onClick={handleClick}
                >
                  <BsXCircleFill style={{ cursor: "pointer" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-4">
          <div className="d-flex flex-lg-row flex-column">
            <div>
              <button
                className={`fixedOrFlex ${earnType === "Fixed" && "fixedOrFlex-active"}`}
                onClick={() => {
                  setEarnType("Fixed");
                }}>Fixed</button>&nbsp;&nbsp;&nbsp;
              <button
                className={`fixedOrFlex ${earnType === "Flexible" && "fixedOrFlex-active"}`}
                onClick={() => {
                  setEarnType("Flexible");
                }}>Flexible</button>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="d-flex flex-lg-row flex-column align-items-lg-end gap-2 mb-4">
            <span className="simpleearn-text-7">Protected Products</span>
            <span className="simpleearn-text-8">
              Get stable earnings by depositing assets
            </span>
          </div>
          <div className="simpleearn-staking-subscribe-table">
            <DataTable
              columns={earnType === "Fixed" ? fixedEarnColumns : flexibleEarnColumn}
              data={stakeCurrencies}
              pagination
              paginationServer
              persistTableHead
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCurrencies}
              paginationRowsPerPageOptions={[5, 10, 15]}
              onChangeRowsPerPage={newRowsPerPage => {
                setRowsPerPage(newRowsPerPage);
                setCurrentPage(1); // Reset to first page when rows per page changes
              }}
              onChangePage={page => setCurrentPage(page)}
              noDataComponent={
                <div className="text-center py-2">
                  <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 60, height: 60 }} />
                  <br />
                  <span>No Records Found</span>
                </div>
              }

            />
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg" >
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Subscribe{" "}
                  <img
                    className="simpleearn-subscribe-img"
                    src={subscribeModal.currencyimage}
                  />
                  <span className="ms-2 fs-14">
                    {subscribeModal.currencysymbol}
                  </span>
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={subModelCloseRef}
                ></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    {earnType === "Fixed" && (<>
                      <p className="mb-1 simpleearn-text-11">Duration (Days)</p>
                      <ul
                        class="simpleearn-table-tabs-duration nav nav-pills gap-2 fs-12"
                        id="pills-tab"
                        role="tablist"
                      >
                        {
                          rewardsapr.map((aprs, index) => (
                            <li key={aprs._id} class="nav-item" role="presentation" onClick={() => setAprIdx(index)}>
                              <button
                                className={index == innerSubModelIdx ? "nav-link active" : "nav-link"}
                                data-bs-toggle="pill"
                                role="tab" type="button"
                                id={`pills-duration${index + 1}-tab`}
                                data-bs-target={`#pills-duration${index + 1}`}
                                aria-controls={`pills-duration${index + 1}`}
                                aria-selected="false"
                                onClick={() =>
                                  handleChangeAPR(aprs.apr, aprs.days)
                                }
                              >
                                {aprs.days}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </>)}
                    <p className="mb-1 simpleearn-text-11 mt-3">
                      Subscription Amount
                    </p>
                    <div class="simpleearn-subscription-amount input-group mb-0">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Amount"
                        aria-label="Enter Amount"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setInvestAmount(e.target.value)}
                        value={investAmount}
                      />
                      <span
                        class="input-group-text subscription-amount-group-1"
                        id="basic-addon2"
                      >
                        <button
                          className="simpleearn-max-button"
                          onClick={() => setInvestAmount(userbalance)}
                        >
                          Max
                        </button>
                      </span>
                      <span
                        class="input-group-text subscription-amount-group-2"
                        id="basic-addon2"
                      >
                        <img
                          className="simpleearn-subscribe-img"
                          src={subscribeModal.currencyimage}
                        />
                        <span className="ms-1 fs-14">
                          {subscribeModal.currencysymbol}
                        </span>
                      </span>
                    </div>
                    <div className="d-flex flex-row">
                      <div>
                        <p className="fs-12 mb-0">
                          {userbalance} {subscribeModal.currencysymbol}{" "}
                          <span className="text-muted">Available</span>
                        </p>
                      </div>
                      <div className="ms-auto">
                        <p className="fs-12 mb-0">
                          <a
                            className="simpleearn-text-12 cursor-poniter"
                            onClick={() => {
                              if (subModelCloseRef && subModelCloseRef.current) {
                                subModelCloseRef.current.click();
                              };
                              navigate(`/deposit/crypto/${subscribeModal.currencysymbol}`)
                            }}
                          >
                            Buy Now
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-1 simpleearn-enough-crypto text-white py-2">
                      <div>
                        <AiFillInfoCircle className="simpleearn-text-13" />
                      </div>
                      <div>
                        <span className="fs-12">Don’t have enough crypto?</span>
                      </div>
                      <div className="ms-auto fs-12">
                        <a
                          className="simpleearn-text-14 cursor-poniter"
                          onClick={() => {
                            if (subModelCloseRef && subModelCloseRef.current) {
                              subModelCloseRef.current.click();
                            };
                            navigate(`/my/dashboard`)
                          }}
                        >
                          {" "}
                          convert <BsArrowRight />
                        </a>
                      </div>
                    </div>
                    <p className="mb-0 mt-3 fs-12">Amount Limits</p>
                    <p className="mb-0 simpleearn-text-15">
                      Minimum:{" "}
                      <span className="text-muted">
                        {subscribeModal.minAmount} {subscribeModal.currencysymbol}
                      </span>
                    </p>
                    <p className="mb-0 simpleearn-text-15">
                      Maximum:{" "}
                      <span className="text-muted">
                        {subscribeModal.maxAmount} {subscribeModal.currencysymbol}
                      </span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <div class="container-timeline">

                      <div class="timeline-block timeline-block-right">
                        <div class="marker active">
                          <i class="fa fa-check active" aria-hidden="true"></i>
                        </div>
                        <div class="timeline-content d-flex align-items-center">
                          <h3 className="simpleearn-text-17">
                            Subscription Date
                          </h3>
                          <span className="ms-auto simpleearn-text-16">
                            {`${currentDate.toLocaleDateString('en-US', options)}  ${currentDate.toLocaleTimeString()}`}
                          </span>
                        </div>
                      </div>

                      {earnType === "Fixed" && (<>
                        <div class="timeline-block timeline-block-right">
                          <div class="marker active">
                            <i class="fa fa-check active" aria-hidden="true"></i>
                          </div>
                          <div class="timeline-content d-flex align-items-center">
                            <h3 className="simpleearn-text-17">
                              Interest End Date
                            </h3>
                            <span className="ms-auto simpleearn-text-16">
                              {`${new Date(expDate).toLocaleDateString('en-US', options)}  ${new Date(expDate).toLocaleTimeString().toString("h:mm:ss a")}`}
                            </span>
                          </div>
                        </div>

                        <div class="timeline-block timeline-block-left">
                          <div class="marker active">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </div>
                          <div class="timeline-content d-flex align-items-center">
                            <h3 className="simpleearn-text-17">
                              Next Subscription Date
                            </h3>
                            <span className="ms-auto simpleearn-text-16">
                              {`${new Date(expDate).toLocaleDateString('en-US', options)}  ${new Date(expDate).toLocaleTimeString()}`}
                            </span>
                          </div>
                        </div>
                      </>)}
                    </div>

                    <div className="d-flex flex-row mt-3">
                      <div>
                        <p className="mb-0 simpleearn-text-15">Est. APR </p>
                      </div>
                      <div className="ms-auto">
                        {aprPercentage}%
                      </div>
                    </div>

                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe mt-1 py-1">
                      <div>
                        <AiFillInfoCircle />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          The APR is subject to change on a daily basis. Please
                          refer to the details on the page.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe py-1">
                      <div>
                        <AiFillInfoCircle />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          APR does not mean the actual or predicted returns in
                          fiat currency.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2 simpleearn-auto-Subscribe py-1">
                      <div>
                        <HiSpeakerphone />
                      </div>
                      <div>
                        <p className="simpleearn-text-16 mb-0">
                          Upon expiry, your digital assets will be automatically
                          subscribed to Flexible Products. If you choose to
                          redeem early, your digital assets will be returned to
                          the spot wallet.
                        </p>
                      </div>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input curPointer"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={() => clickinputbox()}
                      />
                      <label
                        class="form-check-label fs-12"
                        for="flexCheckDefault"
                      >
                        I have read and agreed to{" "}
                        <span style={{ color: "var(--btnClr)" }}>
                          BitNevex Simple Earn Service Agreement
                        </span>
                      </label>
                    </div>

                    <div class="d-grid mt-3">
                      <button
                        class="simpleearn-subscribe-button"
                        type="button"
                        onClick={() => confirmstake()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="whatissimpleearn" tabindex="-1" aria-labelledby="whatissimpleearnLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">What is Simple Earn?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className="row g-4">
                  <div className="col-lg-6">
                    <p className="fs-12">With Binance Simple Earn, you can reap rewards on more than 300 types of digital assets, thereby transforming your investment portfolio's potential. You can earn rewards by depositing your digital assets for either Flexible or Locked periods of time.</p>
                    <p className="fs-12">Subscribe to Simple Earn Products at any time to start earning rewards every minute. Locked Products offer different locked terms to meet different use cases.</p>
                  </div>
                  <div className="col-lg-6">
                    <h5>Benefits</h5>
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <MdOutlineCleanHands className="mt-2" style={{ width: "60px" }} />
                      <div>
                        <p className="mb-0">Lorem ipsum</p>
                        <p className="fs-12 opacity-75">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <MdOutlineCleanHands className="mt-2" style={{ width: "60px" }} />
                      <div>
                        <p className="mb-0">Lorem ipsum</p>
                        <p className="fs-12 opacity-75">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <MdOutlineCleanHands className="mt-2" style={{ width: "60px" }} />
                      <div>
                        <p className="mb-0">Lorem ipsum</p>
                        <p className="fs-12 opacity-75">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row gap-2 justify-content-center">
                      <MdOutlineCleanHands className="mt-2" style={{ width: "60px" }} />
                      <div>
                        <p className="mb-0">Lorem ipsum</p>
                        <p className="fs-12 opacity-75">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}
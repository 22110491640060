import React, { useState, useEffect } from "react";
import "../assets/style-coin-list.css";
import NavbarOne from "../Pages/siteTheme/NavbarOne";
import Footer from "../Pages/siteTheme/Footer";
import { MdOutlineCloudDone } from "react-icons/md";
import Accordion from 'react-bootstrap/Accordion';
import Image2 from "../assets/images/faq_1-1.png";
// import Bannerimage from "../assets/images/landing-banner-2.jpg";
import Payoff from "../assets/images/Pay-off.png";
import Referral from "../assets/images/Referral Bonus.png";
import Reward from "../assets/images/Rewards.png";
import Tracking from "../assets/images/TRacking.png";
import Everytime from "../assets/images/Every Time.png"
import Newinvite from "../assets/images/newinvite.png";
import Newrewards from "../assets/images/newRewards.png";
import Newsharelink from "../assets/images/newsharelink.png";
import Newsignup from "../assets/images/newsignup.png";
import Newtrack from "../assets/images/newtrack_.png";
import Groupingimg from "../assets/images/gropingimg.png";
import Exclusive from "../assets/images/exclusive.png";
import { getCookie } from "../core/helper/cookie";
import {
    BrowserRouter as Router,
    Link,
    useNavigate
} from "react-router-dom";
// import $ from 'jquery';

export default function Withdraw(props) {
    // $( document ).ready(function(){

    //         var $on = 'section';
    //         $($on).css({
    //           'background':'none',
    //           'border':'none',
    //           'box-shadow':'none'
    //         });
    //     }); 
    const navigate = useNavigate();
    const token = getCookie("userToken");
    // console.log("token---", token);
    return (
        <div className="coinlist">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />

            <div className="deposit-page-top-banner">

                <section className="top-full-ban-01">
                    <div className="container ">
                        <div className="row py-5 d-flex align-items-center flex-lg-row-reverse">
                            <div className="col-lg-6 col-12">
                                <div className="second-new-land-01">
                                    <img src={Groupingimg} className="whiteimg" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="ref-pro-ctn-01">
                                    <h3 className="top-getx-head-001 text-start">BitNevex Referral Program</h3>
                                    <p className=" another-new-ctn-01 text-start">Invite & Get <span>(10% to 50%)</span> of the trading fees spend by your referral</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mx-auto py-4">
                                <div className="whole-ref-pro-ctn ">
                                    <div className="tot-listing-ctn my-3">
                                        <h3 className="top-getx-head-001 py-4 text-center">Why join our Referral Program?</h3>
                                        <div className="row lin-ctn-ass">
                                            <div className="col-lg-6">
                                                <ul>
                                                    <li className="py-3 text-center  my-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Payoff} className="pay-off-img-ico" /></span><a className="under-sub-ctn">Get up to 50% as pay-off</a></li>
                                                    <li className="py-3 text-center  my-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Referral} className="pay-off-img-ico" /></span><a className="under-sub-ctn">Live Tracking</a></li>
                                                    <li className="py-3 text-center  my-lg-4 mt-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Reward} className="pay-off-img-ico" /></span><a className="under-sub-ctn">Get Paid Monthly, Every Time</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6">
                                                <ul>
                                                    <li className="py-3 text-center  my-lg-4 mb-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Tracking} className="pay-off-img-ico" /></span><a className="under-sub-ctn ">Never-ending Referral Bonus</a></li>
                                                    <li className="py-3 text-center  my-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Everytime} className="pay-off-img-ico" /></span><a className="under-sub-ctn">Endless Rewards</a></li>
                                                    <li className="py-3 text-center  my-4 tot-child-link-01 position-relative"><span className="tweleve-01 position-absolute"><img src={Exclusive} className="pay-off-img-ico" /></span><a className="under-sub-ctn">Exclusive Discounts</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {token ? (
                                            <p className="text-center">
                                                <button onClick={() => navigate('/my/referral')} className="sign-up-btn-00">Referral</button>
                                            </p>
                                        ) : (
                                            <p className="text-center">
                                                <button onClick={() => navigate('/register')} className="sign-up-btn-00">Sign Up</button>
                                            </p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row getx-ctn-ptn-sec mb-5">
                            <h2 className="text-center my-5 fw-bold getx-how-head-ctn">How the BitNevex Referral Program Works?</h2>
                            <div className="col-lg-8 mx-auto position-relative">
                                <p className="birghtness-theme"></p>
                                <div className="d-flex align-items-center my-2">
                                    <div>
                                        <p className="number"><img src={Newinvite} /></p>
                                    </div>
                                    <div className="ms-lg-5 ms-4">
                                        <span className="fw-bold sign-up-ctn-001" style={{ fontSize: "20px" }}>Sign Up :</span>
                                        <p className="sign-ctn-001">Users register on the BitNevex platform and receive a unique referral link.</p>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <div>
                                        <p className="number"><img src={Newrewards} /></p>
                                    </div>
                                    <div className="ms-lg-5 ms-4">
                                        <span className="fw-bold sign-up-ctn-001" style={{ fontSize: "20px" }}>Share Link :</span>
                                        <p className="sign-ctn-001">Users can share their referral link with friends, and family, or on social media platforms.</p>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <div>
                                        <p className="number"><img src={Newsharelink} /></p>
                                    </div>
                                    <div className="ms-lg-5 ms-4">
                                        <span className="fw-bold sign-up-ctn-001" style={{ fontSize: "20px" }}>Invite Friends :</span>
                                        <p className="sign-ctn-001">Referrers and new users earn rewards when the latter signs up and completes specific actions.</p>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <div>
                                        <p className="number"><img src={Newsignup} /></p>
                                    </div>
                                    <div className="ms-lg-5 ms-4">
                                        <span className="fw-bold sign-up-ctn-001" style={{ fontSize: "20px" }}>Earn Rewards :</span>
                                        <p className="sign-ctn-001">Referrers earn a percentage of trading fees from referred users as direct commission or platform credits.</p>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <div>
                                        <p className="number"><img src={Newtrack} /></p>
                                    </div>
                                    <div className="ms-lg-5 ms-4">
                                        <span className="fw-bold sign-up-ctn-001" style={{ fontSize: "20px" }}>Track Progress :</span>
                                        <p className="sign-ctn-001">Users can monitor referrals and rewards via a dashboard on BitNevex, tracking activity and earnings.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {token ? (
                            <p className="join-btn text-center mt-3">
                                <button onClick={() => navigate('/my/referral')} 
                                >Join our Program</button>
                            </p>
                        ) : (
                            <p className="text-center">
                                <button onClick={() => navigate('/register')} 
                                className="sign-up-btn-00">Join Our Program</button>
                            </p>
                        )}

                    </div>
                </section>

                <div className="affiliate-fourth-section pt-lg-5 mt-lg-5">
                    <div className="container">
                        <div className="row justify-content-lg-around align-items-start g-4  py-5">
                            <div className="col-lg-6 text-center">
                                <h3 className="mb-lg-5">Frequently Asked Questions</h3>
                                <img src={Image2} alt="FAQ" />
                            </div>
                            <div className="col-lg-6">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" className="mb-3">
                                        <Accordion.Header>How much can I get in this referral program?</Accordion.Header>
                                        <Accordion.Body>
                                            Earn up to 50% of your referral's trading fees in INR. Rewards increase with their trading activity, subject to conditions.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="mb-3">
                                        <Accordion.Header>Is there a limit to the number of referrals I can make?</Accordion.Header>
                                        <Accordion.Body>
                                            No, you can refer as many users as you like, with rewards accumulating for each successful referral.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="mb-3">
                                        <Accordion.Header>What criteria must one meet to be eligible for rewards for referrals?</Accordion.Header>
                                        <Accordion.Body>
                                            These are subject to conditions, including KYC verification, PAN-Aadhaar linkage, and meeting trading thresholds.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className="mb-3">
                                        <Accordion.Header>Do we need to apply any TDS on referral rewards?</Accordion.Header>
                                        <Accordion.Body>
                                            10% TDS applies under section 194R. For example, if your monthly reward is INR 500, INR 50 is deducted.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4" className="mb-3">
                                        <Accordion.Header>When do I receive my referral rewards on BitNevex?</Accordion.Header>
                                        <Accordion.Body>
                                            Referral rewards are usually credited to your account shortly after the referred user completes qualifying actions.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </div>

                        </div>
                    </div>
                </div>





            </div>

            <Footer />
        </div>
    );
}



import React, { useState, useEffect } from "react";
import "../../assets/style.css";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import NavbarOne from "../siteTheme/NavbarOne";
import Footer from "../siteTheme/Footer";
import $ from "jquery";
import TradingView from "./tradeChart/trading-view";
import Coin from "../../assets/images/coin-game.png";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config";
import { useContextData } from "../../core/context";
import axios from "axios";
import { toast } from "../../core/lib/toastAlert";
import Pagination from "react-responsive-pagination";
import "../../pagination.css";
import moment from "moment";
import socketIOClient from "socket.io-client";
import socket from "socket.io-client/lib/socket";
import CustomPagination from "./CustomPagination";
let pairData = {};

export default function Prediction(props) {

  const values = [500, 750, 1000];
  const { myProfile } = useContextData();
  const [wager, setWager] = useState(0);
  const [actualWager, setActualWager] = useState(0);
  const [direction, setDirection] = useState("Bull");
  const [errorMessage, setErrorMessage] = useState("");
  const [bustPrice, setBustPrice] = useState([0.0]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [activeBets, setActiveBets] = useState([]);
  const [userId, setUserId] = useState("");
  //==============Socket Io====
  const [socketConnection, setSocketConnection] = useState(null);
  const [apiMarketPrice, setMarketPrice] = useState(0);
  const [highPrice24h, setHighPrice24h] = useState([]);
  const [lowPrice24h, setLowPrice24h] = useState([]);
  const [turnover24h, setTurnover24h] = useState([]);
  const [getUserGamePrediction, setGetUserGamePrediction] = useState([]);  
  // console.log("getUserGamePrediction==",getUserGamePrediction.length);
  
  const [userGameWalletBal, setUserGameWalletBal] = useState(null);
  const [flatFeeCalculation, setFlatFeeCalculation] = useState(null);
  const [exactFeeAmt, setExactFeeAmt] = useState(null);
  const [closeBetData, setCloseBetData] = useState([]);
  const [closeBetTotal, setCloseBetTotal] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isCloseButtonDisabled, setIsCloseButtonDisabled] = useState(false);
  const [disabledButtonId, setDisabledButtonId] = useState(null);
  const [maintainMultiplier, setMaintainMultiplier] = useState(0);
  const [userManualInput, setUserManualInput] = useState(0);
  const recordsPerPage = 5; // Set the number of records to display per page
  const [activeBetData, setActiveBetData] = useState([]);
  const [activeBetTotal, setactiveBetTotal] = useState(0);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [closeBetPage, setCloseBetPage] = useState(1);
  const [currencyImage,setCurrencyImage] = useState(null);
  const [currencydataSymbol,setCurrencydataSymbol] = useState(null);

  //** user Id fetch to using useEffect */
  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      if (myProfile && myProfile._id) {
        setUserId(myProfile._id);
      }
    }
  }, [myProfile]);

  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      if (apiMarketPrice) {
        setListData();
      }
    }
  }, [apiMarketPrice]);


  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      getCurrency();
      getCurrencyData();

    }
  }, []);



  useEffect(() => {
    if (currencySymbol) {
      userGameWalletBalance(currencySymbol);
    }
  }, [currencySymbol]);

  useEffect(() => {
    if (socketConnection != null) {
      socketConnection.emit("pairData")
      socketConnection.on("pairDataRes", (data) => {
      })
    }
  }, [socketConnection])


  // useEffect(() => {
  //   loadSocket();
  // }, []);

  // socket load //
  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      if (socketConnection != null) {
        // loadSocket();
        calculateBustPrice(apiMarketPrice, userManualInput, currentStepIndex, direction)
      }

    }
  }, [socketConnection, bustPrice, wager, currentStepIndex, userManualInput, apiMarketPrice, direction]);




  /*  pagination functionalities */
  const noOfPagesActiveBet = Math.ceil(activeBetTotal / recordsPerPage);

  const noOfPagesCloseBet = Math.ceil(closeBetTotal / recordsPerPage);

  const clickPageNo = (pgNumber, betType) => {
    pageChange(pgNumber, betType);
  };

  const pageChange = (newCurrentPage, betType) => {
    if (betType == "activeBets") {
      if (newCurrentPage >= 1 && newCurrentPage <= Math.ceil(activeBetTotal / recordsPerPage)) {
        setActivePage(newCurrentPage);
        getPagination(newCurrentPage, betType);
      }
    } else if (betType == "closeBets") {
      if (newCurrentPage >= 1 && newCurrentPage <= Math.ceil(closeBetTotal / recordsPerPage)) {
        setCloseBetPage(newCurrentPage);
        getPagination(newCurrentPage, betType);
      }
    }
  };

  const getPagination = async (page, betType) => {
    try {
      const userId = myProfile ? myProfile._id : "";
      const startIndex = (activePage - 1) * recordsPerPage;
      const startIndex1 = (closeBetPage - 1) * recordsPerPage;
      let activeBetStatus =  getUserGamePrediction.filter(
        (activebet) => activebet.predictionStatus == 0
      );
      // console.log("activeBetStatus--", activeBetStatus);
      let closeBetStatus =  getUserGamePrediction.filter(
        (closebet) => closebet.predictionStatus == 1
      );

      const paginatedData = activeBetStatus.slice(
        startIndex,
        startIndex + recordsPerPage
      );
      const paginatedData1 = closeBetStatus.slice(
        startIndex1,
        startIndex1 + recordsPerPage
      );

      if (paginatedData && paginatedData.length > 0) {

        setActiveBetData(paginatedData);
        setactiveBetTotal(activeBetStatus.length);
      }
      if (paginatedData1 && paginatedData1.length > 0) {

        setCloseBetData(paginatedData1);
        setCloseBetTotal(closeBetStatus.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts or when currentPage changes
    getPagination();
  }, [activePage, closeBetPage]);


  function toFixedValue(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }


  const bustPriceCalc = async (newBustVal, index) => {
    if (wager)
      var payoutMultiplier = await payoutMultiplierData(index);
    let newBustPrice = newBustVal
      ? newBustVal * payoutMultiplier
      : wager * payoutMultiplier;
    let recentBust = newBustVal
      ? newBustVal / newBustPrice
      : wager / newBustPrice;

    let findBitAmt = apiMarketPrice * recentBust;

    let total,
      val = "",
      newFlatFee;
    if (direction == "Bull") {
      total = apiMarketPrice - findBitAmt;
    } else {
      total = apiMarketPrice + findBitAmt;
    }
    if (isNaN(Number(total)) || total == 0 || total == "") {
      val = 0.0;
    } else {
      val = total;
    }
  };

  const betDataSeperated = async () => {
    // console.log("betdataseparated---");
    
    try {
      if (getUserGamePrediction && getUserGamePrediction?.length > 0) {
        // console.log("betDataSeperated1");
        
        const startIndex = (activePage - 1) * recordsPerPage;
        const startIndex1 = (closeBetPage - 1) * recordsPerPage;
        let activeTable =  getUserGamePrediction.filter(
          (activeData) => activeData.predictionStatus == 0
        );
        let closeTable =  getUserGamePrediction.filter(
          (activeData) => activeData.predictionStatus == 1
        );
        const paginatedData = activeTable.slice(
          startIndex,
          startIndex + recordsPerPage
        );
        const paginatedData1 = closeTable.slice(
          startIndex1,
          startIndex1 + recordsPerPage
        );

        if (paginatedData && paginatedData.length > 0) {
          
          setActiveBetData(paginatedData);
          setactiveBetTotal(activeTable.length);
        }else{
          setActiveBetData(paginatedData);
          setactiveBetTotal(activeTable.length);
        }
        if (paginatedData1 && paginatedData1.length > 0) {

          setCloseBetData(paginatedData1);
          setCloseBetTotal(closeTable.length);
        }else{
          setCloseBetData(paginatedData1);
          setCloseBetTotal(closeTable.length);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      betDataSeperated();
    }
  }, [getUserGamePrediction]);
  //** render calculation *//

  //===============For Click function=================//
  const handleHalfButtonClick = async () => {

    let decrementedValue = wager / 2; // Wager value divided by 2
    if (decrementedValue >= 1) {
      let roundedValue = decrementedValue.toFixed(2);
      setWager(roundedValue); //
      setUserManualInput(roundedValue);
      setErrorMessage("");
      let payoutMultiplier = await payoutMultiplierData();

      await bustPriceCalc();
      //======Fee Calculcation========
      let findNewFeeCal = decrementedValue * payoutMultiplier;
      let flatFeeAmt = ((findNewFeeCal * 0.04) / 100).toFixed(1);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (
        Number(decrementedValue) + Number(flatFeeAmt)
      ).toFixed(1);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    }
  };

  const handleDoubleButtonClick = async () => {
    let doubledValue = wager * 2;
    setWager(doubledValue);
    setUserManualInput(doubledValue);
    setErrorMessage("");

    await bustPriceCalc();
    let payoutMultiplier = await payoutMultiplierData();

    //=======Fee calculation==============

    let findNewFeeCal = doubledValue * payoutMultiplier;
    let flatFeeAmt = ((findNewFeeCal * 0.04) / 100).toFixed(1);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(doubledValue) + Number(flatFeeAmt)).toFixed(1);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };

  const handleWagerChange = async (e) => {
    let newValue = e.target.value; // target value of wager

    if (newValue.includes(".")) {
      const parts = newValue.split(".");
      if (parts.length > 2) {
        return; // Do not set the value if it contains multiple dots
      }
    }

    if (newValue == 0) {
      setUserManualInput(0);
    } else {
      setUserManualInput(newValue);
      // loadSocket()
    }

    setWager(newValue);
    await bustPriceCalc(newValue, currentStepIndex);
    var payoutMultiplier = await payoutMultiplierData();
    let newBustPrice = newValue * payoutMultiplier;
    //=======Flat Fee Calculation===========//
    let flatFeeAmt = ((newBustPrice * 0.04) / 100).toFixed(1);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(newValue) + Number(flatFeeAmt)).toFixed(1);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };


  const payoutMultiplierData = async (index) => {
    if (index) {
      return values[index];
    } else return values[currentStepIndex];
  };


  //change range value
  const handleRangeChange = async (e) => {
    let index = parseInt(e.target.value);
    if (index) setCurrentStepIndex(index);
    else setCurrentStepIndex(0);
    await bustPriceCalc(wager, index);
    let payoutMultiplier = await payoutMultiplierData(index);
    // let payoutMultiplier = values[index];
    let newBustPrice = wager * payoutMultiplier;
    let flatFeeAmt = ((newBustPrice * 0.04) / 100).toFixed(1);
    // console.log("flatFeeAmt==========", flatFeeAmt);
    setExactFeeAmt(flatFeeAmt);
    let finalFlatFee = (Number(wager) + Number(flatFeeAmt)).toFixed(1);
    setFlatFeeCalculation(toFixedValue(finalFlatFee));
  };


  const handleWagerPaste = (e) => {
    e.preventDefault();
  };

  const handleButtonClick = (direction) => {
    setDirection(direction);
  };


  // finally Placebet function
  async function handlePlaceBet() {
    if (isButtonDisabled) {
      return; // Do nothing if the button is already disabled
    }
    try {

      const payload = {
        wager: wager,
        actualWager: actualWager,
        direction: direction,
        userLoginId: userId,
        currencyId: currencySymbol,
        multiplier: values[currentStepIndex],
        bustprice: bustPrice,
        marketPrice: apiMarketPrice,
        betFeeAmt: exactFeeAmt,
      };
      //** API CALL tefdefey*/
      const params = {
        url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/gamePrediction`,
        method: "POST",
        body: payload,
      };
      setButtonDisabled(true);
      const { status, data, error, message } = await makeRequest(params);
      if (status == true) {
        toast({ status: true, message: message });
        profitCalcultion();
        userGameWalletBalance(currencySymbol);
        setButtonDisabled(false);
        // Reload the current page
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } else {
        toast({ status: false, message: message });
        setButtonDisabled(false);
      }
    } catch (err) {
      console.log("error",err);
      setButtonDisabled(false);
    }
  }

  /// Prediction History //
  const profitCalcultion = async () => {
    try {
      var userID = "";
      if (myProfile && myProfile._id) userID = myProfile._id;
      const payload = {
        userLoginId: userID,
        currencyId: currencySymbol,
      };
  
      const params = {
        url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/userGamePrediction`,
        method: "POST",
        body: payload,
      };
      const response = await makeRequest(params);
      if (response.status == true) {
        if (response.data.length > 0) setActiveBets(response.data);
      }
    } catch (error) {
      console.log("error",error);
    }
   
  };

  const userGameWalletBalance = async (symbol) => {
    
    try {
      var userID = "";
      if (myProfile && myProfile._id) userID = myProfile._id;
      const payload = {
        userLoginId: userID,
        currencyId: symbol,
      };
      const params = {
        url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/userGameWalletBalance`,
        method: "POST",
        body: payload,
      };
      const response = await makeRequest(params);

      if (response.status == true) {
        let balanceAmt = response.data ? response.data.gamePredictionAmount : 0;
        setUserGameWalletBal(Number(balanceAmt).toFixed(2));
      }
    } catch (error) {
      console.log("error--", error);
    }
  };



  const getCurrency = async () => {
    try {
      const params = {
        url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/getCurrencySymbol`,
        method: "GET",
      };

      const response = await makeRequest(params);
      // let currencySymbol = response && response.data && response.data._id ? response.data._id : "";
      if (response && response.status == true) {
        const responseData = response.data;
        setCurrencySymbol(responseData._id);
      }else{
        setCurrencySymbol(null);
      }
    } catch (error) {
      console.error("Error fetching currency:", error);
    }

  }


  const getCurrencyData = async () => {
    try {
      const params = {
        url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/getCurrencyData`,
        method: "GET",
      };

      const response = await makeRequest(params);
      const currencyData = response.data
      if (response.status == true) {
        setCurrencyImage(currencyData.image)
        setCurrencydataSymbol(currencyData.currencySymbol)
      }
    } catch (error) {
      console.error("Error fetching currency:", error);
    }
  }


  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      profitCalcultion();
      userGameWalletBalance(currencySymbol);
      findFlatFee();
    }
  }, [myProfile]);

  //=================Socket Connection================

  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      let socket = socketIOClient(Config.BEAR_AND_BULL_SOCKET_URL, {
        transports: ["websocket"],
      });

      let socketUnsubscribe;
      if (socket) {
        socket.on("connect", function () {
          console.log("socket connected");
          setSocketConnection(socket);
          socketUnsubscribe = socket;

          if (currentStepIndex == 0) {
            setMaintainMultiplier(0);
          } else if (currentStepIndex == 1) {
            setMaintainMultiplier(1);
          } else if (currentStepIndex == 2) {
            setMaintainMultiplier(2);
          }
        });
        socket.on("connect_error", (err) => {
          console.log("socket connect_error", err);
        });
        socket.on("disconnect", function () {
          console.log("socket disconnected");
        });
      }
      return () => {
        if (socketUnsubscribe) {
          socketUnsubscribe.disconnect();
        }
      };


    }
  }, []);

  useEffect(() => {
    if (socketConnection != null) {
      socketConnection.on("betClosed", (data) => {
        if (data.userId == userId) {
          toast({ message: data.message });
          profitCalcultion();
          userGameWalletBalance(currencySymbol);
          betDataSeperated();
          getPagination();
        }
      });
      socketConnection.on("tickerData", (data) => {
        if (data) {
          setMarketPrice(parseFloat(Number(data.c).toFixed(2)));
          setTurnover24h(parseFloat(Number(data.v).toFixed(2)));
          setHighPrice24h(parseFloat(Number(data.h).toFixed(2)));
          setLowPrice24h(parseFloat(Number(data.l).toFixed(2)));
          if (currentStepIndex) multiplayerRangeSet(data);
          let newBustPrice = 0;
          if (maintainMultiplier === 0 && userManualInput !== 0) {
            newBustPrice = userManualInput * 500;
          } else if (maintainMultiplier === 1 && userManualInput !== 0) {
            newBustPrice = userManualInput * 750;
          } else if (maintainMultiplier === 2 && userManualInput !== 0) {
            newBustPrice = userManualInput * 1000;
          }
          let recentBust = 0;
          if (userManualInput == 0) {
            recentBust = 0;
          } else {
            recentBust = userManualInput / newBustPrice;
          }
          document.title =
            data.c +
            " | " +
            data.s +
            " | " +
            Config.SITENAME;
        }
      });

    }
  }, [socketConnection])

  const setListData = async () => {
    try {
      if (activeBets && activeBets.length > 0) {
        var addData = [];
        var loss = [];
        for (let resData of activeBets) {
          if (resData.predictionStatus === 0) {
            let newBustPrice = resData.actualpredictionAmt * resData.multiplier;
            let profitAmt = newBustPrice / resData.marketprice;
            let calProfit;
            let calProfitPrc = null;
            if (resData.direction == "Bull" && resData.predictionStatus == 0) {
              calProfit = profitAmt * (apiMarketPrice - resData.marketprice);
              calProfitPrc = Number(calProfit.toFixed(2));
  
              if (Number(calProfitPrc) < 0 && Math.abs(calProfitPrc) > Number(resData.actualpredictionAmt)) {
                // Close the bet if P&L is greater than the bet price
                await handleClick(resData._id, calProfitPrc, "autoClosed");
                continue;
              }
  
              if (Number(resData.bustprice) == Number(apiMarketPrice)) {
                loss.push(resData);
                if (loss && loss?.length > 0) {
                  if (loss[0].predictionStatus == 0) {
                    await handleClick(loss[0]._id, calProfitPrc, "autoClosed");
                    continue;
                  }
                }
              }
            } else if (resData.direction == "Bear" && resData.predictionStatus == 0) {
              calProfit = profitAmt * (resData.marketprice - apiMarketPrice);
              calProfitPrc = Number(calProfit.toFixed(2));
              if (Number(calProfitPrc) < 0 && Math.abs(calProfitPrc) > Number(resData.actualpredictionAmt)) {
                // Close the bet if P&L is greater than the bet price
                await handleClick(resData._id, calProfitPrc, "autoClosed");
                continue;
              }
              if (Number(resData.bustprice) == Number(apiMarketPrice)) {
                loss.push(resData);
                if (loss && loss?.length > 0) {
                  if (loss[0].predictionStatus == 0) {
                    await handleClick(loss[0]._id, calProfitPrc, "autoClosed");
                    continue;
                  }
                }
              }
            }
  
            // resData.profitAndLoss = calProfitPrc.toFixed(2);
            if (calProfitPrc !== null) {
              resData.profitAndLoss = calProfitPrc.toFixed(2);
            }
          }
          // Object.assign(resData);
          addData.push(resData);
        }
  
        if (addData && addData?.length > 0){        
          setGetUserGamePrediction(addData);
        } 
      }
    } catch (error) {
      console.log("error",error);
    }

   
  };

  const multiplayerRangeSet = async (data) => {
    let payoutMultiplier = await payoutMultiplierData();
    let newBustPrice = wager * payoutMultiplier;
    let recentBust = wager / newBustPrice;
    let findBitAmt = Number(data.price) * recentBust;
    let total = Number(data.price) - findBitAmt;
  };

  async function calculateBustPrice(marketPrice, userInput, multiplierSta, dir) {
    let newBustPrice = 0;
    let userEnterVal = Number(userInput)
    let multiplier = multiplierSta


    if (multiplier === 0 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 500;
      let flatFeeAmt = ((newBustPrice * 0.04) / 100).toFixed(1);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(1);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    } else if (multiplier === 1 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 750;
      let flatFeeAmt = ((newBustPrice * 0.04) / 100).toFixed(1);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(1);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    } else if (multiplier === 2 && userEnterVal !== 0) {
      newBustPrice = userEnterVal * 1000;
      let flatFeeAmt = ((newBustPrice * 0.04) / 100).toFixed(1);
      let actualWagerAmt = userEnterVal - flatFeeAmt;
      setActualWager(actualWagerAmt);
      setExactFeeAmt(flatFeeAmt);
      let finalFlatFee = (Number(userEnterVal) + Number(flatFeeAmt)).toFixed(1);
      setFlatFeeCalculation(toFixedValue(finalFlatFee));
    }

    let recentBust = 0;
    if (userEnterVal == 0) {
      recentBust = 0
    } else {
      recentBust = userEnterVal / newBustPrice;
    }

    let findBitAmt = marketPrice * recentBust;
    let total = dir === 'Bull' ? marketPrice - findBitAmt : marketPrice + findBitAmt;
    if (userEnterVal === 0) {
      setBustPrice(0);
    } else {
      setBustPrice(total.toFixed(2));
    }
  }

  const findFlatFee = async () => {
    let payoutMultiplier = await payoutMultiplierData();
    let wagerAndMultiplier = wager * payoutMultiplier;
    let flatFeeCalc = (wagerAndMultiplier * 0.04) / 100;
    let finalFlatFeeAmt = wager + flatFeeCalc;
    setFlatFeeCalculation(toFixedValue(finalFlatFeeAmt));
  };

  const handleClick = async (id, value, positionStr) => {
    if (!isCloseButtonDisabled) {
      try {
        const payload = {
          predictionId: `${id}`,
          profitAndLoss: `${value}`,
          exitPrice: `${apiMarketPrice}`,
          userLoginId: `${userId}`,
          currencyId: `${currencySymbol}`,
        };

        const params = {
          url: `${Config.BEAR_AND_BULL_V1_API_URL}bear_bull/userClosedBet`,
          method: "POST",
          body: payload,
        };

        const { status, data, error, message } = await makeRequest(params);
        setIsCloseButtonDisabled(true);
        setDisabledButtonId(id);
        if (positionStr == "autoClosed" && status == true) {
          toast({ status: true, message: message });
          profitCalcultion();
          userGameWalletBalance(currencySymbol);
          betDataSeperated();
          getPagination();
        } else if (positionStr == "handleClose" && status == true) {
          toast({ status: true, message: message });
          profitCalcultion();
          userGameWalletBalance(currencySymbol);
          betDataSeperated();
          getPagination();
        }
      } catch (err) {
        toast({ status: false, message: err });
      }
    }
  };

  useEffect(() => {
    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      $(".status_change .dropdown-item").click(function () {
        var getStatusText = $(this).text();
        $(this)
          .closest(".status_dropdown")
          .find(".status__btn")
          .text(getStatusText);
        var generateStatusClass = `${$(this).attr("data-className")}-status`;
        $(this)
          .closest(".status_dropdown")
          .attr("data-color", `${generateStatusClass}`);
      });
    }
  }, []);
  useEffect(() => {
    $(document).ready(function () {
      $("#flexRadioDefault4").click(function () {
        window.location.reload(false);
      });
      $("#flexRadioDefault2").click(function () {
        window.location.reload(false);
      });
    });

    if (Config.BEAR_AND_BULL_STATUS == "Enable") {
      $(document).ready(function () {
        $(".prediction-trade-settings-section .dropdown-menu").click(function (
          e
        ) {
          e.stopPropagation();
        });
        $("#features-dropdown-open").click(function () {
          $(".features-dropdown-hidden").hide();
          $("#features-dropdown-open").hide();
          $("#features-dropdown-close").show();
        });
        $("#features-dropdown-close").click(function () {
          $(".features-dropdown-hidden").show();
          $("#features-dropdown-open").show();
          $("#features-dropdown-close").hide();
        });
        $("#features-chats-section-close").click(function () {
          $(".prediction-page-right-section").css("width", "0%");
          $(".prediction-page-middle-section").css("width", "87%");
        });
      });
    }
  });
  return (
    <div>
      <NavbarOne setTheme={props.setTheme} theme={props.theme} />
      <div className="prediction-trade-top-banner tournament-active-nav-class">
        <div className="container-fluid p-0">
          <div className="d-flex flex-lg-row flex-column gap-4">
            <div className="container-fluid prediction-trade-right-section p-0">
              <div className=" d-flex flex-lg-row flex-column gap-3">
                <div className="prediction-trade-right-1 pt-4">
                  <div className="d-flex flex-lg-row flex-column align-items-center mb-3">
                    <div>
                      <img
                        className="prediction-trade-image-1"
                        src={currencyImage}
                        alt="btc"
                      />
                    </div>
                    <div>
                      <span>{currencydataSymbol}/USDT </span>
                      <span className="ms-lg-2">{apiMarketPrice}</span>
                    </div>
                    <div>
                      <span className="prediction-trade-text-2 mb-0 ms-lg-3">
                        <span className="prediction-trade-text-3">
                          24h Volume:
                        </span>{" "}
                        {turnover24h}{" "}
                      </span>
                      <span className="prediction-trade-text-2 mb-0 ms-lg-3">
                        <span className="prediction-trade-text-3">H:</span>{" "}
                        <span style={{ color: "lightgreen" }}>
                          {highPrice24h}
                        </span>{" "}
                      </span>
                      <span className="prediction-trade-text-2 mb-0 ms-lg-3">
                        <span className="prediction-trade-text-3">L:</span>{" "}
                        <span style={{ color: "red" }}>{lowPrice24h}</span>{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    id="tradingviewwidget-linechart"
                    className="position-relative"
                  >
                    <TradingView theme={props.theme} />
                  </div>
                </div>
                <div className="prediction-trade-right-2">
                  <h4 className="benefits-text-1">MANUAL</h4>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-manual-tabs"
                      role="tabpanel"
                      aria-labelledby="pills-manual-tabs-tab"
                      tabindex="0"
                    >
                      <span className="prediction-trade-text-4">
                        WILL THE PRICE GO UP OR DOWN?
                      </span>
                      <div className="prediction-manual-updown-tabs">
                        <ul
                          className="nav nav-pills mb-3 nav-fill mt-2"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => handleButtonClick("Bull")}
                          >
                            <button
                              className="nav-link manual-active-up active"
                              id="pills-manual-up-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-manual-up"
                              type="button"
                              role="tab"
                              aria-controls="pills-manual-up"
                              aria-selected="true"
                            >
                              <MdKeyboardDoubleArrowUp className="prediction-trade-text-5" />
                              Bull
                            </button>
                          </li>
                          <li
                            className="nav-item"
                            role="presentation"
                            onClick={() => handleButtonClick("Bear")}
                          >
                            <button
                              className="nav-link manual-active-down"
                              id="pills-manual-down-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-manual-down"
                              type="button"
                              role="tab"
                              aria-controls="pills-manual-down"
                              aria-selected="false"
                            >
                              <MdKeyboardDoubleArrowDown className="prediction-trade-text-5" />
                              Bear
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-manual-up"
                          role="tabpanel"
                          aria-labelledby="pills-manual-up-tab"
                          tabindex="0"
                        >
                          <span className="prediction-trade-text-4">
                            BET PRICE
                          </span>
                          <div className="input-group my-2 prediction-trade-updown-input prediction-overrall-border">
                            <span className="input-group-text">
                              <img
                                className="prediction-manual-coin"
                                src={Coin}
                                alt="coin"
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              value={wager}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={handleWagerChange}
                              onKeyPress={(event) => {
                                const keyCode = event.which || event.keyCode;
                                const keyValue = String.fromCharCode(keyCode);

                                // Allow only numeric values (0-9) and the backspace key (8)
                                if (!/^[0-9\b.]+$/.test(keyValue)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleWagerPaste}
                            />
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleHalfButtonClick}
                            >
                              1/2
                            </button>
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleDoubleButtonClick}
                            >
                              x2
                            </button>
                          </div>
                          <span className="prediction-trade-text-4">
                            MULTIPLIER
                          </span>
                          <div className="row align-items-center mt-2 g-2">
                            <div className="col-lg-6">
                              <div className="input-group prediction-trade-updown-input py-1 prediction-overrall-border">
                                <span className="input-group-text">
                                  <img
                                    className="prediction-manual-coin"
                                    src={Coin}
                                    alt="coin"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values[currentStepIndex]}
                                  aria-label="Example text with button addon"
                                  aria-describedby="button-addon1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <p className="mb-0 prediction-trade-text-6">
                                Bust Price:
                              </p>
                              <p className="mb-0 prediction-trade-text-6">
                                {bustPrice === 0 || isNaN(bustPrice) ? (
                                  <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                ) : (
                                  bustPrice
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="">
                              <input
                                className="form-range-prediction"
                                onInput={handleRangeChange}
                                type="range"
                                min="0"
                                value={currentStepIndex}
                                max="2"
                                step="1"
                                list="tick-list"
                              />
                            </div>
                            <div className="d-flex flex-row-reverse ">
                              <div>
                                {values[currentStepIndex] == 500 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#0c491d" }}>
                                      Safe
                                    </span>
                                    : x500
                                  </span>
                                )}
                                {values[currentStepIndex] == 750 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f2a819" }}>
                                      Moderate
                                    </span>
                                    : x750
                                  </span>
                                )}
                                {values[currentStepIndex] == 1000 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f23838" }}>
                                      Risk
                                    </span>
                                    : x1000
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="prediction-bet-button-up mt-3"
                            disabled={isButtonDisabled || wager < 1}
                            onClick={handlePlaceBet}
                          >
                            PLACE BET
                          </button>
                          {wager < 1 && (
                            <p className="error-message">
                              * Wager must be at least $1
                            </p>
                          )}
                          <div className="mt-3">
                            <span>Flat Fee : 0.04%</span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              We need to deduct {exactFeeAmt || 0} USDT from
                              placing bet
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              Betting will close in 2 minute.
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 mb-0">
                              <span className="prediction-trade-text-3">
                                Game Wallet Balance:
                              </span>{" "}
                              {userGameWalletBal ? userGameWalletBal : 0} USDT{" "}
                            </span>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-manual-down"
                          role="tabpanel"
                          aria-labelledby="pills-manual-down-tab"
                          tabindex="0"
                        >
                          <span className="prediction-trade-text-4">
                            BET PRICE
                          </span>
                          <div className="input-group my-2 prediction-trade-updown-input prediction-overrall-border">
                            <span className="input-group-text">
                              <img
                                className="prediction-manual-coin"
                                src={Coin}
                                alt="coin"
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              value={wager}
                              aria-label="Example text with button addon"
                              aria-describedby="button-addon1"
                              onChange={handleWagerChange}
                              onKeyPress={(event) => {
                                const keyCode = event.which || event.keyCode;
                                const keyValue = String.fromCharCode(keyCode);

                                // Allow only numeric values (0-9) and the backspace key (8)
                                if (!/^[0-9\b.]+$/.test(keyValue)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleWagerPaste}
                            />
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleHalfButtonClick}
                            >
                              1/2
                            </button>
                            <button
                              className="prediction-updown-button"
                              type="button"
                              id="button-addon1"
                              onClick={handleDoubleButtonClick}
                            >
                              x2
                            </button>
                          </div>
                          <span className="prediction-trade-text-4">
                            MULTIPLIER
                          </span>
                          <div className="row align-items-center mt-2 g-2">
                            <div className="col-lg-6">
                              <div className="input-group prediction-trade-updown-input py-1 prediction-overrall-border">
                                <span className="input-group-text">
                                  <img
                                    className="prediction-manual-coin"
                                    src={Coin}
                                    alt="coin"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values[currentStepIndex]}
                                  aria-label="Example text with button addon"
                                  aria-describedby="button-addon1"
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <p className="mb-0 prediction-trade-text-6">
                                Bust Price:
                              </p>
                              <p className="mb-0 prediction-trade-text-6">
                                {bustPrice === 0 || isNaN(bustPrice) ? (
                                  <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                  </div>
                                ) : (
                                  bustPrice
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="">
                              <input
                                className="form-range-prediction"
                                onInput={handleRangeChange}
                                type="range"
                                min="0"
                                value={currentStepIndex}
                                max="2"
                                step="1"
                                list="tick-list"
                              />
                            </div>

                            <div className="d-flex flex-row-reverse ">
                              <div>
                                {values[currentStepIndex] == 500 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#0c491d" }}>
                                      Safe
                                    </span>
                                    : x500
                                  </span>
                                )}
                                {values[currentStepIndex] == 750 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f2a819" }}>
                                      Moderate
                                    </span>
                                    : x750
                                  </span>
                                )}
                                {values[currentStepIndex] == 1000 && (
                                  <span className="prediction-trade-text-6">
                                    <span style={{ color: "#f23838" }}>
                                      Risk
                                    </span>
                                    : x1000
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="prediction-bet-button-down mt-3"
                            disabled={isButtonDisabled || wager < 1}
                            onClick={handlePlaceBet}
                          >
                            PLACE BET
                          </button>
                          {wager < 1 && (
                            <p className="error-message">
                              * Wager must be at least $1
                            </p>
                          )}
                          <div className="mt-3">
                            <span>Flat Fee : 0.04%</span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              We need to deduct {exactFeeAmt || 0} USDT from
                              placing bet
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 flat-fee-span">
                              Betting will close in 2 minute.
                            </span>
                          </div>
                          <div>
                            <span className="prediction-trade-text-2 mb-0">
                              <span className="prediction-trade-text-3">
                                Game Wallet Balance:
                              </span>{" "}
                              {userGameWalletBal} USDT
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prediction-trade-right-section-table-section">
                <div className="row justify-content-center mt-4">
                  <div className="col">
                    <div className="d-flex flex-lg-row flex-column gap-2">
                      <div>
                        <div className="prediction-bets-tabs-section">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link fs-14 active"
                                id="pills-all-bets-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-all-bets"
                                type="button"
                                role="tab"
                                aria-controls="pills-all-bets"
                                aria-selected="true"
                              >
                                Active Bets
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                className="nav-link fs-14"
                                id="pills-lucky-bets-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-lucky-bets"
                                type="button"
                                role="tab"
                                aria-controls="pills-lucky-bets"
                                aria-selected="false"
                              >
                                Closed Bets
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 tab-content" id="nav-tabContent">
                      <div
                        className="prediction-bets-table-section tab-pane fade show active"
                        id="pills-all-bets"
                        role="tabpanel"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" className="bets-table-trade-1">
                                Date
                              </th>
                              <th scope="col" className="bets-table-trade-2">
                                Pair
                              </th>
                              <th scope="col" className="bets-table-trade-3">
                                BULL / BEAR
                              </th>
                              {/* <th scope="col" className="bets-table-trade-4">
                                FEE
                              </th> */}
                              <th scope="col" className="bets-table-trade-5">
                                Entry Price
                              </th>
                              <th scope="col" className="bets-table-trade-6">
                                multiplier
                              </th>
                              <th scope="col" className="bets-table-trade-7">
                                bet price
                              </th>
                              <th scope="col" className="bets-table-trade-8">
                                Bust Price
                              </th>
                              <th scope="col" className="bets-table-trade-9">
                                P & L
                              </th>
                              <th scope="col" className="bets-table-trade-10">
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeBetData && activeBetData.length > 0 ? (
                              activeBetData.map((active) => {
                                const isButtonDisabled =
                                  active._id === disabledButtonId &&
                                  isCloseButtonDisabled;
                                return (
                                  <tr>
                                    <td className="bets-trade-tablebd-1">
                                    {moment(active.dateTime).format("DD-MM-YYYY hh:mm:ss A")}
                                    </td>
                                    <td className="bets-trade-tablebd-2">
                                    {active.pairName}
                                    </td>
                                    <td className="bets-trade-tablebd-3">
                                      {active.direction}
                                    </td>
                                    {/* <td className="bets-trade-tablebd-4">
                                      {active.feeAmount}
                                    </td> */}
                                    <td className="bets-trade-tablebd-5">
                                      {active.marketprice}
                                    </td>
                                    <td className="bets-trade-tablebd-6">
                                      {active.multiplier}
                                    </td>
                                    <td className="bets-trade-tablebd-7">
                                      {active.predictionAmt}
                                    </td>
                                    <td className="bets-trade-tablebd-8">
                                      {active.bustprice}
                                    </td>
                                    <td className={`bets-trade-tablebd-9 ${active.profitAndLoss < 0 ? "negativeVal" : "positiveVal"}`}>
                                      {active.profitAndLoss}
                                    </td>
                                    <td className="bets-trade-tablebd-10">
                                      {" "}
                                      <button
                                        className={`prediction-updown-button m-0 fs-10 ${isButtonDisabled ? "disabled" : ""
                                          }`}
                                        type="button"
                                        id="button-addon1"
                                        onClick={() =>
                                          handleClick(
                                            active._id,
                                            active.profitAndLoss,
                                            "handleClose"
                                          )
                                        }
                                        disabled={isButtonDisabled}
                                      >
                                        CLOSE
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="10">No Active Bet</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {
                          (activeBetTotal > recordsPerPage) && (
                            <div className="row">
                              <div className="col-lg-12 d-flex justify-content-end">
                                <CustomPagination
                                  className="p2p-trade-pagination"
                                  total={Math.ceil(
                                    activeBetTotal / recordsPerPage
                                  )}
                                  current={activePage}
                                  onPageChange={(page) => clickPageNo(page, "activeBets")}
                                />
                              </div>
                            </div>
                          )
                        }
                      </div>
                      <div
                        className="prediction-bets-table-section tab-pane fade"
                        id="pills-lucky-bets"
                        role="tabpanel"
                      >
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col" className="bets-table-trade-1">
                                Date
                              </th>
                              <th scope="col" className="bets-table-trade-2">
                                Pair
                              </th>
                              <th scope="col" className="bets-table-trade-3">
                                Entry Price
                              </th>
                              <th scope="col" className="bets-table-trade-4">
                                BEAR / Bull
                              </th>
                              <th scope="col" className="bets-table-trade-5">
                                FEE
                              </th>
                              <th scope="col" className="bets-table-trade-6">
                                multiplier
                              </th>
                              <th scope="col" className="bets-table-trade-7">
                                bet price
                              </th>
                              <th scope="col" className="bets-table-trade-8">
                                Bust Price
                              </th>
                              <th scope="col" className="bets-table-trade-9">
                                Exit Price
                              </th>
                              <th scope="col" className="bets-table-trade-10">
                                P & L
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {closeBetData && closeBetData.length > 0 ? (
                              closeBetData.map((active) => {
                                return (
                                  <tr key={active.id}>
                                    <td className="bets-trade-tablebd-1">
                                    {moment(active.dateTime).format("DD-MM-YYYY hh:mm:ss A")}
                                    </td>
                                    <td className="bets-trade-tablebd-2">
                                    {active.pairName}
                                    </td>
                                    <td className="bets-trade-tablebd-3">
                                      {active.marketprice}
                                    </td>
                                    <td className="bets-trade-tablebd-4">
                                      {active.direction}
                                    </td>
                                    <td className="bets-trade-tablebd-5">
                                      {active.feeAmount}
                                    </td>
                                    <td className="bets-trade-tablebd-6">
                                      {active.multiplier}
                                    </td>
                                    <td className="bets-trade-tablebd-7">
                                      {active.predictionAmt}
                                    </td>
                                    <td className="bets-trade-tablebd-8">
                                      {active.bustprice}
                                    </td>
                                    <td className="bets-trade-tablebd-9">
                                      {active.exitMarketPrice}
                                    </td>
                                    <td className="bets-trade-tablebd-10">
                                      {active.winAmt == 0 ? (
                                        <span className="negativeVal">
                                          {active.lossAmt.toFixed(2)}
                                        </span>
                                      ) : (
                                        <span className="positiveVal">
                                          {active.winAmt.toFixed(2)}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="10">No Closed Bet</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {
                          (closeBetTotal > recordsPerPage) && (
                            <div className="row">
                              <div className="col-lg-12 d-flex justify-content-end">
                                <CustomPagination className="p2p-trade-pagination"
                                  total={Math.ceil(closeBetTotal / recordsPerPage)}
                                  current={closeBetPage}
                                  onPageChange={(page) => clickPageNo(page, "closeBets")}
                                />
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

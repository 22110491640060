import React, { useEffect, useState } from "react";
import ReactTooltip from 'react-tooltip';
import { GoDotFill } from "react-icons/go";

const OrderBook = (props) => {

  const {
    decimalValue: decimalValueFunc,
    viewOrderList = [],
    pairDetails = {},
    myProfile = {}
  } = props;
  const {
    decimalValue: toDecimal = 2,
    amountDecimal = 0,
    priceDecimal = 0,
    totalDecimal = 0,
  } = pairDetails;

  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);

  useEffect(() => {
    let getMinValue = 0;
    let getMaxValue = 0;
    viewOrderList.map((elmObj, index) => {
      const totalMin = (elmObj.amount - elmObj.filledAmount) * elmObj._id;
      if (totalMin != 0) {
        if (getMinValue > totalMin) {
          getMinValue = totalMin;
        }
      }
      const totalMax = (elmObj.amount - elmObj.filledAmount) * elmObj._id;
      if (getMaxValue < totalMax) {
        getMaxValue = totalMax;
      }
    });
    setMinVal(getMinValue);
    setMaxVal(getMaxValue);
  }, [viewOrderList]);
  return (
    <>
      <ul>
        {
          props.viewOrderList && props.viewOrderList.length > 0
            ?
            props.viewOrderList.slice(0, props.viewLimit).map((elmObj, index) => {
              const total = (elmObj.amount - elmObj.filledAmount) * elmObj._id;
              const perVal = (total / maxVal) * 100;
              const classIndexbased = index === 0 ? "m-0 p-0" : "";

              const clrBarClass = props.orderListType === 'all' || props.orderListType === 'sell' ? "ask-bar" : "bid_bar_imp";
              const clrClass = props.orderListType === 'all' || props.orderListType === 'sell' ? "color-red" : "color-green";
              return (
                <li key={index} className={classIndexbased} >
                  <div className="d-flex flex-row mobile-justify-content  cursor-poniter" onClick={() => props.clickOrderBook(elmObj)}>
                    <div className={"progress-bar " + (clrBarClass)} style={{ width: perVal + "%" }}></div>
                    {
                      Object.keys(myProfile).length !== 0 ? (
                        myProfile?._id == elmObj?.userId ? <GoDotFill style={{ fontSize: "10px" }} className={clrClass} /> : null
                      ) : null
                    }
                    <div className={"progress-table-1 " + (clrClass)}><span className={'orderbook-text-2'}>{decimalValueFunc(elmObj._id, priceDecimal)}</span></div>
                    <div className="progress-table-2"><span className='orderbook-text-2'>{decimalValueFunc(elmObj.amount - elmObj.filledAmount, amountDecimal)}</span></div>
                    <div className="progress-table-3"><span className='orderbook-text-2'>{decimalValueFunc((elmObj.amount - elmObj.filledAmount) * elmObj._id, totalDecimal)}</span></div>
                  </div>
                </li>
              )
            })
            :
            ""
        }
      </ul>
    </>
  )
}

export default OrderBook;